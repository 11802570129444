<app-toolbar [showUserName] = "usernameToolBar"
[matBadgeCount]="matBadgeCount"
[username] = "userName" (loginClicked)="openLogin($event)"></app-toolbar>
<!-- laptop view -->
<div class="dashboard-wrapper" style="padding-top: 70px;" *ngIf="this.screenType.breakpointState !== 'mob'">
    <div class="overlay" *ngIf="spinner">
        <div class="spinner">
          <img src="../../../../assets/loader.svg" alt="" class="loader">
        </div>
    </div>
    <div *ngIf="isData && isDataLoaded">

    <div class="name-header row" style="margin-top: 40px;" *ngIf="!existinguser">
      <div class="col-8">
        <div class="heading-wrapper" style="display: flex; flex-wrap: wrap;">
          <span class="headerText">{{jarDetails?.jar_name}} &nbsp;
            <span class="risk-wrapper" [ngStyle]="{
              'background-color': jarDetails?.risk_level === 'MEDIUM' ? '#F9F5F1' : 
              jarDetails?.risk_level === 'LOW' ? '#E4F3E3' : 
              jarDetails?.risk_level === 'HIGH' ? '#FFECED' : ''
            }">
          <span class="risk-text" [ngStyle]="{
              'color': jarDetails?.risk_level === 'MEDIUM' ? '#9B6F49' : 
              jarDetails?.risk_level === 'LOW' ? '#0AC01C' : 
              jarDetails?.risk_level === 'HIGH' ? '#FF4347' : ''
            }">
              {{jarDetails?.risk_level | camelCase}} Risk
          </span>
          </span>
           <div style="margin-top: 12px;">
              <span class="subText">{{jarDetails?.description}}</span>
           </div>
          </span>
          <!-- <span class="riskType"><span class="riskTypeText">Medium Risk&nbsp;&nbsp;</span></span>
          <span class="headerText"><img src="../../../../assets/informationIcon.svg" alt=""></span> -->
      </div>
      </div>
      <div class="col-4">
        <div style="display: flex; gap: 30px; justify-content: flex-end;">
          <div>
              <div>Min Investment </div> 
              <div><i class="fa fa-rupee"></i>&nbsp;<span style="font-weight: 600; color:#001E5A; font-size: 18px; line-height: 27px;">{{minimum_Investement_Amount | decimalFormat}}</span></div>
          </div>

          <!-- <div>
              <img src="../../../../assets/hr_vertical.svg" alt="">
          </div>
  
          <div>
              <div>3Y CAGR</div>
              <div
              [ngStyle]="{
                'color': jarDetails?.cagr < 0 ? '#E17466' : '#0AC01C',
                'font-weight': '600',
                'font-size': '18px',
                'line-height': '27px'
              }"
              ><img [src]="jarDetails?.cagr < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'" alt=""> {{jarDetails?.cagr}}%</div>
          </div> -->
      </div>
      </div>
        
     <!-- <div class="col-2" style="display: flex; align-items: center;">
        <span><a (click)="goToInsights(jarDetails)" class="anchorTag"><img src="../../../../assets/insightsIcon.svg" alt=""><span style="font-size: 14px;">More Insights</span></a></span>
     </div> -->
     <!-- <div class="col-3">
        <div class="industryContainer">
            <div>
            <div>5Y CAGR <img src="../../../../assets/tooltipTagIcon.svg"
            matTooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." alt=""></div>
            <div style="color: #0D7816; font-weight: 600; font-size: 18px; line-height: 27px;"><img [src]="jarDetails?.cagr < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'" alt=""> {{jarDetails?.cagr}}%</div>
            </div>

            <div>
                <div>Min Investment <img src="../../../../assets/tooltipTagIcon.svg"
                matTooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." alt=""></div> 
                <div><i class="fa fa-rupee"></i>&nbsp;<span style="font-weight: 600; color:#001E5A; font-size: 18px; line-height: 27px;">{{minimum_Investement_Amount | decimalFormat}}</span></div>
            </div>
             
        </div>
     </div> -->
    </div>
    <div class="cards-wrapper">
        <mat-card class="card-content">
          <mat-card-content>
            <div class="card-inside">
                <div><img src="../../../../assets/ic_Invested Amount.svg" alt=""> &nbsp;</div>
                <div>
                    <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(existinguser ? mainBasketData?.invested_amount : investementDetails?.invested_amount) | decimalFormat}}<br>
                    <span class="headSubText">Invested Amount</span></span>
                </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="card-content">
          <mat-card-content>
            <div class="card-inside">
            <div><img src="../../../../assets/ic_Current Value.svg" alt=""> &nbsp;</div>
            <div>
            <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(existinguser ? mainBasketData?.current_value : investementDetails?.current_value) | decimalFormat}}<br>
            <span class="headSubText">Current Value</span></span>
            </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="card-content">
          <mat-card-content>
            <div class="card-inside">
            <div><img src="../../../../assets/ic_Total Returns.svg" alt=""> &nbsp;</div>
            <div>
            <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(existinguser ? mainBasketData?.total_returns : investementDetails?.total_returns) | decimalFormat}}&nbsp;
                <span style="position: absolute; top: 7px; right: 10px;" 
                [class.incrementText]="(existinguser ? mainBasketData?.total_returns_percentage : investementDetails?.total_returns_percentage) >= 0" 
                [class.decrementText]="(existinguser ? mainBasketData?.total_returns_percentage : investementDetails?.total_returns_percentage) < 0"
                ><img src="../../../../assets/upwardArrowIcon.svg" alt="" *ngIf="(existinguser ? mainBasketData?.total_returns_percentage : investementDetails?.total_returns_percentage) >= 0">
                <img src="../../../../assets/downwardArrow.svg" alt="" *ngIf="(existinguser ? mainBasketData?.total_returns_percentage : investementDetails?.total_returns_percentage) < 0">
                {{(existinguser ? mainBasketData?.total_returns_percentage : investementDetails?.total_returns_percentage) | decimalFormat}}%</span><br>
            <span class="headSubText">Total Returns</span></span>
            </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="card-content">
          <mat-card-content>
            <div class="card-inside">
            <div><img src="../../../../assets/ic_Day's P&L.svg" alt=""> &nbsp;</div>
            <div>
                <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(existinguser ? mainBasketData?.daily_profit_loss : investementDetails?.daily_profit_loss) | decimalFormat}}&nbsp;
                    <span style="position: absolute; top: 7px; right: 10px;" 
                    [class.incrementText]="(existinguser ? mainBasketData?.daily_profit_loss_percentage : investementDetails?.daily_profit_loss_percentage) >= 0" 
                    [class.decrementText]="(existinguser ? mainBasketData?.daily_profit_loss_percentage : investementDetails?.daily_profit_loss_percentage) < 0"
                    >
                    <img src="../../../../assets/upwardArrowIcon.svg" alt="" *ngIf="(existinguser ? mainBasketData?.daily_profit_loss_percentage : investementDetails?.daily_profit_loss_percentage) >= 0">
                    <img src="../../../../assets/downwardArrow.svg" alt="" *ngIf="(existinguser ? mainBasketData?.daily_profit_loss_percentage : investementDetails?.daily_profit_loss_percentage) < 0">
                    {{(existinguser ? mainBasketData?.daily_profit_loss_percentage : investementDetails?.daily_profit_loss_percentage)}}%</span><br>
                <span class="headSubText">Day’s P&L</span></span>
            </div>
            </div>
          </mat-card-content>
        </mat-card>
    </div>
      

    <!--  -->
    <div class="row" style="margin-top: 20px;">
    <img src="../../../../assets/hrDarkTagIcon.svg" alt="">
    </div>
    <div class="parent-container">
      <div class="rebalancing-info" *ngIf="currentUser && rebalancingInProgress">
        <span class="text"><img src="../../../../assets/info_blue.svg" alt=""
          matTooltip="We're rebalancing your portfolio. Please wait a moment, and you'll be able to continue shortly.">&nbsp;
          Your Rebalancing is in Progress...</span>
      </div>
    </div>
    <br>
    <!-- current user table data -->
    <mat-card *ngIf="false">
        <mat-card-content>
            <div class="stocks-wrapper" style="margin-top: 20px; padding-left: 13px;">
                <div class="row">
                    <div class="col-4">
                        <div style="display: flex;">
                          <div class="stockType" 
                          [ngClass]="{'active': selectedStockType === 'active', 'disabled': selectedStockType === 'exited'}" 
                          (click)="selectStockType('active')">
                       Active Stocks
                     </div>
                     &nbsp;&nbsp;
                     <div class="stockType" 
                          [ngClass]="{'exited': selectedStockType === 'exited', 'disabled': selectedStockType === 'active'}" 
                          (click)="selectStockType('exited')">
                       Exited Stocks
                     </div>
                     
                        </div>  
                    </div>
                    <div class="col-3">
                    </div>
                    <div class="col-5" style="display: flex; justify-content: flex-end; gap: 8px;">
                      <div class="stock-btn" *ngIf="goBackRoute">
                        <app-button [isTransparentBTN]="true" [savingsText]="'Go Back'"
                        [showWatchListIcon]="false" [borderColor]="'#324066'"
                        [textColor]="'#324066'" [goBackIcon]="true"
                        (click)="goBackToDashboard()"></app-button>
                        <!-- <button mat-stroked-button color="primary" (click)="goBackToDashboard()"><img src="../../../../assets/backArrow.svg" alt="">&nbsp; Go Back</button> -->
                      </div>
                        <app-button
                        *ngIf="isBasketRebalanced"
                        [savingsText]="'Rebalancing History'"
                        [backgroundColor]="'#001E5A'" (click)="goToRebalancingPage()"></app-button>
                        <app-button
                        *ngIf="isRenewNow"
                        [savingsText]="'Renew Now'"
                        [backgroundColor]="'#E17466'"
                        (click)="renewNow('Basket Details')"
                        ></app-button>
                        <!-- (click)="renewNow()" -->
                        <app-button *ngIf="!isRenewNow" [savingsText]="invest_now_text" [backgroundColor]="getBackgroundColor(invest_now_text)"  (click)="openInvestementModal()"></app-button>
                    </div>
                </div>
                <div class="row" style="padding-top: 15px;">
                <span class="fix_investment_text" *ngIf="invest_now_text == 'Fix Investment'">In the last investment highlighted stocks were purchased partially. To maintain the correct weightage click on Fix Investment</span>
                </div>
                <div class="row" *ngIf="activeStocks">
                    <table class="table">
                        <thead>
                          <tr>
                            <th scope="col" class="headerTable" style="border-top-left-radius: 8px; border-bottom-left-radius: 8px;">Stocks Name</th>
                            <th scope="col" class="headerTable">Weightage</th>
                            <th scope="col" class="headerTable">Closing Price</th>
                            <th scope="col" class="headerTable">Target Price 
                              <img src="../../../../assets/information.svg" alt=""
                                   matTooltip="Target Price is the best possible price range for a stock">
                            </th>
                            <th scope="col" class="headerTable" *ngIf="isUserPortfolio">Average Price</th>
                            <th scope="col" class="headerTable" *ngIf="isUserPortfolio">Quantity</th>
                            <th scope="col" class="headerTable">Upside Potential</th>
                            <th scope="col" class="headerTable" style="border-top-right-radius: 8px; border-bottom-right-radius: 8px;">Reports</th>
                          </tr>
                        </thead>
                        <tbody class="tbody-style">
                          <tr *ngFor="let stock of stocksList; let i = index" style="cursor: pointer;" (click)="openTransactionDetails(stock)">
                            <td [ngStyle]="{
                              'background-color': 
                              isUserPortfolio && stock.order_failed === true 
                                ? '#FFECED' 
                                : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                                  ? '' 
                                  : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                                    ? '#FFECED' 
                                    : i % 2 === 1 
                                      ? '#F4F4F4' 
                                      : ''
                                }">
                             <img src="../../../../assets/plpusIcon.svg" alt=""> &nbsp; {{ stock.company_short_name }}
                            </td>
                            <td [ngStyle]="{
                              'background-color': 
                              isUserPortfolio && stock.order_failed === true 
                                ? '#FFECED' 
                                : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                                  ? '' 
                                  : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                                    ? '#FFECED' 
                                    : i % 2 === 1 
                                      ? '#F4F4F4' 
                                      : ''
                                }">
                              {{ stock.allocation }} %
                            </td>
                            <td [ngStyle]="{
                              'background-color': 
                              isUserPortfolio && stock.order_failed === true 
                                ? '#FFECED' 
                                : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                                  ? '' 
                                  : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                                    ? '#FFECED' 
                                    : i % 2 === 1 
                                      ? '#F4F4F4' 
                                      : ''
                                }">
                                <i class="fa fa-rupee" *ngIf="stock.last_cmp"></i> {{ stock.last_cmp | decimalFormat }}
                            </td>
                            <td [ngStyle]="{
                              'background-color': 
                              isUserPortfolio && stock.order_failed === true 
                                ? '#FFECED' 
                                : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                                  ? '' 
                                  : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                                    ? '#FFECED' 
                                    : i % 2 === 1 
                                      ? '#F4F4F4' 
                                      : ''
                                }">
                                <i class="fa fa-rupee" *ngIf="stock.target_range_start"></i> {{ stock.target_range_start | decimalFormat }} - <i class="fa fa-rupee" *ngIf="stock.target_range_end"></i>
                                 {{ stock.target_range_end | decimalFormat }}
                            </td>
                            <td *ngIf="isUserPortfolio" [ngStyle]="{
                              'background-color': 
                                isUserPortfolio && stock.order_failed === true 
                                  ? '#FFECED' 
                                  : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                                    ? '' 
                                    : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                                      ? '#FFECED' 
                                      : i % 2 === 1 
                                        ? '#F4F4F4' 
                                        : ''
                            }"
                            >
                              <ng-container *ngIf="(stock.avg_buying_price && !(stock.quantity_bought < stock.quantity_recommended)); else statusTemplate">
                                <i class="fa fa-rupee" *ngIf="stock.avg_buying_price"></i> {{ stock.avg_buying_price | decimalFormat }}
                              </ng-container>
                              <ng-template #statusTemplate>
                                <span style="color: #2997FF;">
                                  {{ stock.order_failed === true 
                                      ? 'Order Rejected' 
                                      : (stock.quantity_bought === 0 && stock.order_failed === false 
                                          ? stock.corrective_action 
                                          : (stock.quantity_bought < stock.quantity_recommended 
                                              ? 'Partially Executed' 
                                              : '')) 
                                  }}
                                </span>
                              </ng-template>
                            </td>
                            <td *ngIf="isUserPortfolio" [ngStyle]="{
                              'background-color': 
                              isUserPortfolio && stock.order_failed === true 
                                ? '#FFECED' 
                                : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                                  ? '' 
                                  : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                                    ? '#FFECED' 
                                    : i % 2 === 1 
                                      ? '#F4F4F4' 
                                      : ''
                                }">
                              {{ stock.quantity || stock.quantity_bought || 0 }}
                            </td>
                            <td style="color: #0D7816;" [ngStyle]="{
                              'background-color': 
                              isUserPortfolio && stock.order_failed === true 
                                ? '#FFECED' 
                                : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                                  ? '' 
                                  : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                                    ? '#FFECED' 
                                    : i % 2 === 1 
                                      ? '#F4F4F4' 
                                      : ''
                                }">
                              <span
                              *ngIf="stock.upside_potential"
                              [ngStyle]="{
                                'background-color': stock.upside_potential < 0 ? '#FFECED' : '#E4F3E3',
                                'padding': '5px',
                                'border-radius': '12px'
                            }"
                              >
                              <img [src]="stock.upside_potential < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'" alt="">
                              {{ stock.upside_potential }} %
                              </span>
                              <span
                              style="color: #2C3A47;"
                              *ngIf="!stock.upside_potential"
                              >
                              NA <img src="../../../../assets/information.svg" alt="" matTooltip="Target range not available, please reach out to support">
                              </span>
                            </td>
                            <td [ngStyle]="{
                              'background-color': 
                              isUserPortfolio && stock.order_failed === true 
                                ? '#FFECED' 
                                : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                                  ? '' 
                                  : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                                    ? '#FFECED' 
                                    : i % 2 === 1 
                                      ? '#F4F4F4' 
                                      : ''
                                }">
                              <img style="height: 20px; cursor: pointer;" (click)="openPDF(stock.report_url, stock); $event.stopPropagation()" src="../../../../assets/pdfIcon.svg" alt="">
                            </td>
                          </tr>
                        </tbody>
                    </table>                  
                      
                </div>
                <div class="row" *ngIf="exitedStocks">
                  <table class="table" style="width: 100%; border-collapse: collapse;">
                    <thead>
                      <tr>
                        <th scope="col" class="headerTable" style="border-top-left-radius: 8px; border-bottom-left-radius: 8px;">Stocks Name</th>
                        <th scope="col" class="headerTable">Quantity</th>
                        <th scope="col" class="headerTable">Buying Price(₹)</th>
                        <th scope="col" class="headerTable">Selling Price(₹)</th>
                        <th scope="col" class="headerTable">Invested Value(₹)</th>
                        <th scope="col" class="headerTable">Exit Value(₹)</th>
                        <th scope="col" class="headerTable">Returns Value</th>
                        <th scope="col" class="headerTable">Exit Date</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="exitedStocksList.length === 0">
                      <tr>
                        <td colspan="8" style="text-align: center; padding: 20px 0; color: #9AA6AC; font-weight: 700; font-size: 16px;">
                          No Data Found
                        </td>
                      </tr>
                    </tbody>
                    <tbody class="tbody-style" *ngIf="exitedStocksList.length > 0">
                      <tr *ngFor="let data of exitedStocksList; let i = index" style="cursor: pointer;" (click)="openTransactionDetails(data)">
                        <td [ngStyle]="{
                          'background-color': i % 2 === 1 
                                  ? '#F4F4F4' 
                                  : ''
                            }">
                          <img src="../../../../assets/plpusIcon.svg" alt=""> &nbsp;
                          {{ data.company_short_name }}
                        </td>
                        <td [ngStyle]="{
                          'background-color': i % 2 === 1 
                                  ? '#F4F4F4' 
                                  : ''
                            }">
                          {{ data.quantity }}
                        </td>
                        <td [ngStyle]="{
                          'background-color': i % 2 === 1 
                                  ? '#F4F4F4' 
                                  : ''
                            }">
                          {{ data.avg_buying_price | decimalFormat}}
                        </td>
                        <td [ngStyle]="{
                          'background-color': i % 2 === 1 
                                  ? '#F4F4F4' 
                                  : ''
                            }">
                          {{ data.avg_selling_price | decimalFormat}}
                        </td>
                        <td [ngStyle]="{
                          'background-color': i % 2 === 1 
                                  ? '#F4F4F4' 
                                  : ''
                            }">
                          {{ data.buying_value }}
                        </td>
                        <td [ngStyle]="{
                          'background-color': i % 2 === 1 
                                  ? '#F4F4F4' 
                                  : ''
                            }">
                          {{ data.selling_value }}
                        </td>
                        <td [ngStyle]="{
                          'background-color': i % 2 === 1 ? '#F4F4F4' : '',
                          'color': (data?.selling_value - data?.buying_value) < 0 ? '#FF4347' : '#1A9F17'
                        }">
                        <span>
                          {{(data?.selling_value - data?.buying_value) | decimalFormat}}
                        </span>
                          <span [ngStyle]="{ 
                            'background-color': (data?.selling_value - data?.buying_value) < 0 ? '#FFECED' : '#E4F3E3',
                          }" style="padding: 5px;
                          border-radius: 12px;">
                          <img [src]="(data?.selling_value - data?.buying_value) < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'" alt="">
                          &nbsp;
                          {{ (data?.performance) | decimalFormat }} %</span>
                        </td>
                        <td [ngStyle]="{
                          'background-color': i % 2 === 1 
                                  ? '#F4F4F4' 
                                  : ''
                            }">
                          {{ formatDate(data.selling_date) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  
              </div>
            </div>
        </mat-card-content>
    </mat-card>
    <!--  -->
    
    <!-- existing user table data -->
    <div class="stocks-wrapper-existingUser" style="margin-top: 20px;" *ngIf="existinguser">
        <div class="container-text">
            <span class="head-text">My Baskets</span>
            <hr>
        </div>          
        <div class="cards-container" style="margin-top: 20px;" [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'justify-content': userListData.length === 1 ? 'center' : 'space-between'}">
            <mat-card *ngFor="let card of userListData" [ngStyle]="{'width': userListData.length === 1 ? '49%' : '49%', 'margin-bottom': '20px'}" style="border-radius: 6px;">
                <mat-card-content>
                    <div class="user-card">
                        <div>
                            <span class="headerText">{{ card.jar_name }}</span>
                        </div>
                        <div class="user-sub-content">
                        <div *ngIf="card.portfolio_state == 'rebalance_in_progress'">
                          <span class="risk-wrapper" [ngStyle]="{
                            'background-color': '#FFF3CC'}">
                        <span class="risk-text" [ngStyle]="{
                            'color':'#A15F00'}">
                            {{'Rebalancing In Progress' | camelCase}}
                        </span>
                        </span>
                        </div>
                        <div>
                          <span class="risk-wrapper" [ngStyle]="{
                            'background-color': card.risk_level === 'MEDIUM' ? '#F9F5F1' : 
                                                card.risk_level === 'LOW' ? '#E4F3E3' : 
                                                card.risk_level === 'HIGH' ? '#FFECED' : ''
                          }">
                        <span class="risk-text" [ngStyle]="{
                            'color': card.risk_level === 'MEDIUM' ? '#9B6F49' : 
                                     card.risk_level === 'LOW' ? '#0AC01C' : 
                                     card.risk_level === 'HIGH' ? '#FF4347' : ''
                          }">
                            {{card.risk_level | camelCase}} Risk
                        </span>
                        </span>
                        </div>
                      </div>
                    </div>
                    <div class="portfolio-nudge-wrapper" *ngIf="card?.portfolio_nudge">
                        <span 
                          class="portfolio-nudge" 
                          [ngClass]="{
                            'fix-investment': card?.portfolio_state === 'fix_investment',
                            'order-pending': card?.portfolio_state === 'order_in_progress'
                          }">
                          {{card?.portfolio_nudge}}
                        </span>
                    </div>
                    <!-- <div class="risk-content">Portfolio has been rebalanced</div> -->
                    <div>
                        <img src="../../../../assets/dashboardHRIcon.svg" alt="" width="100%">
                    </div>
                    <div class="matcard-content" style="display: flex; justify-content: space-between;">
                        <div>
                            <div class="headerText">Invested Amount</div>
                            <div class="headData"
                            [ngStyle]="{ color: card?.investment_amount < 0 ? '#E17466' : '#0D7816' }">
                                <i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{ card?.investment_amount | decimalFormat }}
                            </div>
                        </div>
                        <div>
                            <div class="headerText">Current Investment</div>
                            <div class="headData"
                            [ngStyle]="{ color: card?.current_value < 0 ? '#E17466' : '#0D7816' }">
                                <i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{ card?.current_value | decimalFormat }}
                            </div>
                        </div>
                        <div>
                            <div class="headerText">Total Returns</div>
                            <div class="headData"
                            [ngStyle]="{ color: card?.performance < 0 ? '#E17466' : '#0D7816' }">
                                <img [src]="card?.performance < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'"
                                 alt="">&nbsp;{{ card?.performance }} %
                            </div>
                        </div>
                        <div>
                          <div class="headerText">Day's P&L</div>
                          <div class="headData"
                          [ngStyle]="{ color: card?.daily_profit_loss_percentage < 0 ? '#E17466' : '#0D7816' }">
                              <img [src]="card?.daily_profit_loss_percentage < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'"
                               alt="">&nbsp;{{ card?.daily_profit_loss_percentage }} %
                          </div>
                      </div>
                    </div>
                    <div>
                        <img src="../../../../assets/dashboardHRIcon.svg" alt="" width="100%">
                    </div>
                    <div style="display: flex; justify-content: flex-end;">
                        <app-button style="margin-right: 10px;" [savingsText]="'Basket Details'" [backgroundColor]="'transparent'" [color]="'#2A62D6'" (click)="investMoreExistingUser('Basket Details', card, getBtnText(card))"></app-button>
                        <app-button [savingsText]="getBtnText(card)" [backgroundColor]="btnColorExistingUser" (click)="investMoreExistingUser(getBtnText(card), card, getBtnText(card))"></app-button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
      <div class="container-text">
          <span class="head-text">Recommended Baskets</span>
          <hr>
      </div>
      <div class="cards-container recommended-wrapper">
        <img src="../../../../assets/backwardIcon.svg"
        *ngIf="recommendedBasketList.length>2"
        (click)="scrollCards('left')" style="cursor: pointer;">
        
        <div class="recommeded-content">
          <mat-card *ngFor="let card of recommendedBasketList" class="mat-card-content">
            <mat-card-content>
              <div class="user-card" style="flex-direction: column;">
                <div>
                  <span class="headerText">{{ card.sub_product_name }}</span>
                  &nbsp;
                    <span class="risk-wrapper" [ngStyle]="{
                      'background-color': card.pb_details?.risk_level === 'MEDIUM' ? '#F9F5F1' : 
                                          card.pb_details?.risk_level === 'LOW' ? '#E4F3E3' : 
                                          card.pb_details?.risk_level === 'HIGH' ? '#FFECED' : ''
                    }">
                  <span class="risk-text" [ngStyle]="{
                      'color': card.pb_details?.risk_level === 'MEDIUM' ? '#9B6F49' : 
                               card.pb_details?.risk_level === 'LOW' ? '#0AC01C' : 
                               card.pb_details?.risk_level === 'HIGH' ? '#FF4347' : ''
                    }">
                      {{card.pb_details?.risk_level | camelCase}} Risk
                  </span>
                  </span>
                </div>
                <div style="margin-top: 8px;">
                  <span class="sub-headerText">{{ card.product_description }}</span>
                </div>
              </div>
              <div class="matcard-content" style="display: flex; justify-content: space-between; margin-top: 30px;">
                <div>
                  <div class="headText-recommended">Minimum Investment</div>
                  <div class="headData" style="color: #001E5A;">
                    <i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{ card?.pb_details?.min_investment_amount | decimalFormat }}
                  </div>
                </div>
                <div>
                  <div class="headText-recommended">Investment period</div>
                  <div class="headData" style="color: #001E5A;">
                    {{ card?.investment_horizon }}
                  </div>
                </div>
                <!-- <div>
                  <div class="headText-recommended">3Y CAGR</div>
                  <div class="headData">
                    <img [src]="card?.pb_details?.cagr < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'"
                         alt="">&nbsp;{{ card?.pb_details?.cagr ?? 0 }} %
                  </div>
                </div> -->
              </div>
              <div>
                <img src="../../../../assets/dashboardHRIcon.svg" alt="" width="100%">
              </div>
              <div class="matcard-content-recommended">
                <div>
                  <span class="headText-recommended">Basket Price: <span class="price-recommended"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{ card?.fees_details[0].details.advisory_fees | decimalFormat }}</span></span>
                  <div class="gst-text">
                    (Exclusive of all taxes)
                  </div>
                  <div class="more-details">
                    <span class="text" (click)="basketMoreDetails(card)">More Details</span>
                  </div>
                </div>
                <div>
                  <app-button
                  [savingsText]="card?.is_getting_renewed 
                                              ? 'Renew Now' 
                                              : !card?.is_subscribed && !card?.is_getting_onboard
                                              ? 'Subscribe Now' 
                                              : !card?.is_subscribed && card?.is_getting_onboard
                                              ? 'Continue'
                                              : 'No Action Available'" 
                  [backgroundColor]="'#C3966E'" (click)="buyRecommededBasket(card)"></app-button>
                </div>
              </div>
            </mat-card-content>
            <div class="svg-wrapper">
              <img src="../../../../assets/basket_icon.svg" *ngIf="!card.is_watch_listed && !card?.is_subscribed" alt="Icon" class="top-right-svg"
              (click)="addToWatchList(card, card.is_watch_listed); $event.stopPropagation()"
              matTooltip="Add to Watchlist">
              <img src="../../../../assets/basket_icon_watchlisted.svg" *ngIf="card.is_watch_listed" alt="Icon" class="top-right-svg"
              (click)="addToWatchList(card, card.is_watch_listed); $event.stopPropagation()"
              matTooltip="Watchlisted">
          </div>
          </mat-card>
        </div>
      
        <img src="../../../../assets/forwardIcon.svg" 
        *ngIf="recommendedBasketList.length>2"
        (click)="scrollCards('right')" style="cursor: pointer;">
      </div>
      
    </div>
    
    <!--  -->
    <div class="footer-items">
        <div>
          <div  class="reach-out">
          <a href="mailto: support@equentis.com">
              <img src="../../../../assets/reachOut.svg" alt="" style="cursor: pointer;" href = "mailto: support.multiplyrr@equentis.com">
          </a>
          </div>
        </div>
        <div>
          <div  class="learn-more">
            <a href="https://www.equentis.com/informedinvestorr/home" target="_blank">
                <img src="../../../../assets/longTerm.svg" alt="Long Term" style="cursor: pointer;">
              </a>                      
        </div>
        </div>
    </div>

    </div>
    <div class="center" style="align-items: center; height: 100vh;" *ngIf="!isData">
     <img src="../../../../assets/Empty_State_No Data_Found.svg" alt="">
    </div>
</div>
<!--  -->

<!-- mobile view -->
<div class="dashboard-wrapper" *ngIf="this.screenType.breakpointState == 'mob'" style="margin-bottom: 70px; padding: 12px;">
    <app-toolbar-mobile></app-toolbar-mobile>
    <div class="overlay" *ngIf="spinner">
      <div class="spinner">
        <img src="../../../../assets/loader.svg" alt="" class="loader">
      </div>
  </div>
    <div *ngIf="isData">
    <div class="row" *ngIf="!existinguser">
        <div class="col-12 heading-wrapper" style="display: flex; flex-wrap: wrap;">
            <span class="headerText">{{jarDetails?.jar_name}}&nbsp;&nbsp;</span>
            <!-- <span class="riskType"><span class="riskTypeText">Medium Risk&nbsp;&nbsp;</span></span>
            <span><img src="../../../../assets/informationIcon.svg" alt=""></span> -->
        </div>
    </div>
    <br>
    <div class="row" *ngIf="!existinguser">
        <span style="font-size: 14px; font-weight: 400; color: #7E8B99;">{{jarDetails?.description}}</span>
    </div>
    <div class="row" *ngIf="false">
        <span style="display: flex; justify-content: flex-end;"><a (click)="goToInsights(jarDetails)" class="anchorTag"><img src="../../../../assets/insightsIcon.svg" alt=""><span style="font-size: 14px;">More Insights</span></a></span> 
    </div>
    <div class="cards-wrapper">
        <mat-card style="width: 47%; border-radius: 10px;">
            <mat-card-content>
                <div style="display: flex;"><img src="../../../../assets/ic_Invested Amount.svg" alt=""></div><br>
                <span class="headText" style="font-size: 22px;"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(existinguser ? mainBasketData?.invested_amount : investementDetails?.invested_amount) | decimalFormat}}<br>
                <span class="headSubText">Invested Amount</span></span>
            </mat-card-content>
        </mat-card>
        <mat-card style="width: 47%; border-radius: 10px;">
            <mat-card-content>
                <div style="display: flex;"><img src="../../../../assets/ic_Current Value.svg" alt=""></div><br>
                <span class="headText" style="font-size: 22px;"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(existinguser ? mainBasketData?.current_value : investementDetails?.current_value) | decimalFormat}}<br>
                <span class="headSubText">Current Value</span></span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="cards-wrapper">
        <mat-card style="width: 47%; border-radius: 10px;">
            <mat-card-content style="position: relative;">
                <div style="display: flex;"><img src="../../../../assets/ic_Total Returns.svg" alt=""></div><br>
                <span class="headText" style="font-size: 22px;"><i class="fa fa-inr" aria-hidden="true"></i>{{(existinguser ? mainBasketData?.total_returns : investementDetails?.total_returns) | decimalFormat}}<br>
                <span class="headSubText">Total Returns</span></span>
                <span style="position: absolute; top: 9px; right: 7px; padding: 10px;" 
                [class.incrementText]="(existinguser ? mainBasketData?.total_returns_percentage : investementDetails?.total_returns_percentage) >= 0" 
                [class.decrementText]="(existinguser ? mainBasketData?.total_returns_percentage : investementDetails?.total_returns_percentage) < 0"
                >{{(existinguser ? mainBasketData?.total_returns_percentage : investementDetails?.total_returns_percentage)}}%</span>
            </mat-card-content>
        </mat-card>
        <mat-card style="width: 47%; border-radius: 10px;">
            <mat-card-content>
                <div style="display: flex;"><img src="../../../../assets/ic_Day's P&L.svg" alt=""></div><br>
                <span class="headText" style="font-size: 22px;"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(existinguser ? mainBasketData?.daily_profit_loss : investementDetails?.daily_profit_loss) | decimalFormat}}<br>
                <span class="headSubText">Day’s P&L</span></span>
                <span style="position: absolute; top: 9px; right: 7px; padding: 10px;" 
                [class.incrementText]="(existinguser ? mainBasketData?.daily_profit_loss_percentage : investementDetails?.daily_profit_loss_percentage) >= 0" 
                [class.decrementText]="(existinguser ? mainBasketData?.daily_profit_loss_percentage : investementDetails?.daily_profit_loss_percentage) < 0"
                >{{(existinguser ? mainBasketData?.daily_profit_loss_percentage : investementDetails?.daily_profit_loss_percentage)}}%</span>
            </mat-card-content>
        </mat-card>
    </div>
    
    <div class="row" style="margin-top: 20px;">
    <img src="../../../../assets/hrTagsmallRiskProfile.svg" alt="">
    </div>
    <div class="stocks-wrapper" style="margin-top: 20px;" *ngIf="currentUser">
        <div style="display: flex;">
          <div class="stockType" 
          [ngClass]="{'active': selectedStockType === 'active', 'disabled': selectedStockType === 'exited'}" 
          (click)="selectStockType('active')">
       Active Stocks
     </div>
     &nbsp;
     <div class="stockType" 
                          [ngClass]="{'exited': selectedStockType === 'exited', 'disabled': selectedStockType === 'active'}" 
                          (click)="selectStockType('exited')">
                       Exited Stocks
      </div>
     <!-- &nbsp;&nbsp;
     <div class="stockType" 
          [ngClass]="{'exited': selectedStockType === 'exited', 'disabled': selectedStockType === 'active'}" 
          (click)="selectStockType('exited')">
       Exited Stocks
     </div> -->
        </div>
        <div class="goBack" style="padding-top: 15px;">
          <app-button [isTransparentBTN]="true" [savingsText]="'Go Back'"
                        [showWatchListIcon]="false" [borderColor]="'#324066'"
                        [textColor]="'#324066'" [goBackIcon]="true"
                        (click)="goBackToDashboard()"></app-button>
        <!-- <div *ngIf="goBackRoute"><a (click)="goBackToDashboard()">Go Back</a></div> -->
        </div>


        <!-- <th scope="col" class="headerTable" *ngIf="isUserPortfolio">Average Price ( <i class="fa fa-rupee"></i> )</th>
                    <th scope="col" class="headerTable" *ngIf="isUserPortfolio">Shares</th>
                    <th scope="col" class="headerTable" *ngIf="isUserPortfolio">Returns</th> -->
        
        <div class="mt-3 mob-table" *ngIf="activeStocks">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let stock of stocksList" class="custom-expansion-panel">
                  <mat-expansion-panel-header 
                    [ngStyle]="{
                      'background-color': 
                      isUserPortfolio && stock.order_failed === true 
                        ? '#FFECED' 
                        : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                          ? ''
                          : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                            ? '#FFECED' 
                            : ''
                    }">
                    <mat-panel-title>
                      {{ stock.company_short_name }}
                    </mat-panel-title>
                    <mat-panel-description>
                      {{ stock.allocation }}%
                    </mat-panel-description>
                  </mat-expansion-panel-header>
              
                  <div class="d-flex align-items-center">
                    <div>Weightage</div>
                    <div class="ms-auto mt-2">Closing Price</div>
                  </div>
                  <div class="d-flex align-items-center" 
                    [ngStyle]="{
                      'background-color': 
                      isUserPortfolio && stock.order_failed === true 
                        ? '#FFECED' 
                        : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                          ? '' 
                          : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                            ? '#FFECED' 
                            : ''
                    }">
                    <div>{{ stock.allocation }} %</div>
                    <div class="ms-auto mt-2"><i class="fa fa-rupee" *ngIf="stock.last_cmp"></i> {{ stock.last_cmp | decimalFormat }}</div>
                  </div>
              
                  <div class="d-flex align-items-center">
                    <div>Target Price 
                      <span>
                        <img src="../../../../assets/information.svg" alt="" matTooltip="Target Price is the best possible price range for a stock">
                      </span>
                    </div>
                    <div class="ms-auto mt-2" *ngIf="isUserPortfolio">Average Price ( <i class="fa fa-rupee"></i> )</div>
                  </div>
                  <div class="d-flex align-items-center" 
                    [ngStyle]="{
                      'background-color': 
                      isUserPortfolio && stock.order_failed === true 
                        ? '#FFECED' 
                        : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                          ? '' 
                          : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                            ? '#FFECED' 
                            : ''
                    }">
                    <div><i class="fa fa-rupee" *ngIf="stock.target_range_start"></i> {{ stock.target_range_start | decimalFormat }} - <i class="fa fa-rupee" *ngIf="stock.target_range_end"></i> {{ stock.target_range_end | decimalFormat }}</div>
                    <div class="ms-auto mt-2" *ngIf="isUserPortfolio">
                      <ng-container *ngIf="(stock.avg_buying_price && !(stock.quantity_bought < stock.quantity_recommended)); else statusTemplate">
                        <i class="fa fa-rupee" *ngIf="stock.avg_buying_price"></i> {{ stock.avg_buying_price | decimalFormat }}
                      </ng-container>
                      <ng-template #statusTemplate>
                        <!-- <span style="color: #2997FF;">
                          {{ stock.quantity_bought === 0 ? 'Order Rejected' : (stock.quantity_bought < stock.quantity_recommended ? 'Partially Executed' : '') }}
                        </span> -->
                        <span style="color: #2997FF;">
                          {{ stock.order_failed === true 
                              ? 'Order Rejected' 
                              : (stock.quantity_bought === 0 && stock.order_failed === false 
                                  ? stock.corrective_action 
                                  : (stock.quantity_bought < stock.quantity_recommended 
                                      ? 'Partially Executed' 
                                      : '')) 
                          }}
                        </span>
                      </ng-template>
                    </div>
                  </div>
              
                  <div class="d-flex align-items-center" *ngIf="isUserPortfolio">
                    <div>Quantity</div>
                    <div class="ms-auto mt-2">Upside Potential</div>
                  </div>
                  <div class="d-flex align-items-center" 
                    *ngIf="isUserPortfolio" 
                    [ngStyle]="{
                      'background-color': 
                      isUserPortfolio && stock.order_failed === true 
                        ? '#FFECED' 
                        : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                          ? '' 
                          : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                            ? '#FFECED' 
                            : ''
                    }">
                    <div>{{ stock.quantity || stock.quantity_bought || 0 }}</div>
                    <div class="ms-auto mt-2" style="color: #0D7816;">
                      <img *ngIf="stock.upside_potential" [src]="stock.upside_potential < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'" alt="">
                      <span *ngIf="stock.upside_potential">{{ stock.upside_potential }} %</span>
                      <span *ngIf="!stock.upside_potential" style="color: #2C3A47;">NA</span>
                    </div>
                  </div>
              
                  <hr [ngStyle]="{
                    'background-color': 
                    isUserPortfolio && stock.order_failed === true 
                      ? '#FFECED' 
                      : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                        ? '' 
                        : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                          ? '#FFECED' 
                          : ''
                  }">
                  <span (click)="openPDF(stock.report_url, stock)" [ngStyle]="{
                    'background-color': 
                    isUserPortfolio && stock.order_failed === true 
                      ? '#FFECED' 
                      : isUserPortfolio && (stock.quantity_bought === 0 && stock.order_failed === false) 
                        ? '' 
                        : isUserPortfolio && stock.quantity_bought < stock.quantity_recommended 
                          ? '#FFECED' 
                          : ''
                  }">
                    <img src="../../../../assets/pdfIcon.svg" alt="">
                    <span style="color: #2997FF;">
                      <u>Research Report</u>
                    </span>
                  </span>
                </mat-expansion-panel>
              </mat-accordion>
               
        </div>
        <div class="mt-3 mob-table" *ngIf="exitedStocks">
          <div *ngIf="exitedStocksList.length === 0">
              <span colspan="8" style="
                display: flex;
                justify-content: center;
                padding: 20px 0; color: #9AA6AC; font-weight: 700; font-size: 16px;">
                No Data Found
              </span>
          </div>
          <div *ngIf="exitedStocksList.length > 0">
            <mat-accordion>
              <mat-expansion-panel *ngFor="let stock of exitedStocksList" class="custom-expansion-panel">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ stock.company_short_name }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
            
                <div class="d-flex align-items-center">
                  <div>Quantity</div>
                  <div class="ms-auto mt-2">Buying Price(₹)</div>
                </div>
                <div class="d-flex align-items-center">
                  <div>{{ stock.quantity }}</div>
                  <div class="ms-auto mt-2"><i class="fa fa-rupee"></i> {{ stock.avg_buying_price | decimalFormat}}</div>
                </div>

                <div class="d-flex align-items-center">
                  <div>Selling Price(₹)</div>
                  <div class="ms-auto mt-2">Invested Value(₹)</div>
                </div>
                <div class="d-flex align-items-center">
                  <div>{{ stock.avg_selling_price }}</div>
                  <div class="ms-auto mt-2"><i class="fa fa-rupee"></i> {{ stock.buying_value}}</div>
                </div>

                <div class="d-flex align-items-center">
                  <div>Exit Value(₹)</div>
                  <div class="ms-auto mt-2">Returns Value</div>
                </div>
                <div class="d-flex align-items-center">
                  <div>{{ stock.selling_value }}</div>
                  <div class="ms-auto mt-2"
                  [ngStyle]="{ 
                    'background-color': (stock?.selling_value - stock?.buying_value) < 0 ? '#FFECED' : '#E4F3E3',
                  }" style="padding: 5px;
                  border-radius: 12px;"
                  >
                    <img [src]="(stock?.selling_value - stock?.buying_value) < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'" alt="">
                    &nbsp;
                    {{ (stock?.selling_value - stock?.buying_value) | decimalFormat}}</div>
                </div>

                <div class="d-flex align-items-center">
                  <div>Exit Date</div>
                </div>

                <div class="d-flex align-items-center">
                  <div>{{ formatDate(stock.selling_date) }}</div>
                </div>
              </mat-expansion-panel>
          </mat-accordion>
          </div>
         </div>
        <div style="display: flex; justify-content: center; margin-top: 15px; flex-direction: column; align-items: center;">
          <app-button
          *ngIf="isRenewNow"
          [savingsText]="'Renew Now'"
          [backgroundColor]="'#E17466'"
          (click)="renewNow('Basket Details')"
          ></app-button>
          <!-- (click)="renewNow()" -->
          &nbsp;&nbsp;
            <app-button [savingsText]="'Rebalancing History'" *ngIf="isBasketRebalanced" [backgroundColor]="'#001E5A'" (click)="goToRebalancingPage()"></app-button>
            &nbsp;&nbsp;
            <app-button *ngIf="!isRenewNow" [savingsText]="invest_now_text" [backgroundColor]="getBackgroundColor(invest_now_text)" (click)="openInvestementModal()"></app-button>
        </div>  
    </div>
    <!-- existing user table data -->
    <div class="row stocks-wrapper-existingUser" style="margin-top: 20px;" *ngIf="existinguser">
      <div class="container-text">
        <span class="head-text">My Baskets</span>
        <hr>
    </div> 
        <div style="margin-top: 20px;" *ngFor="let card of userListData">
            <mat-card style="border-radius: 6px;">
                <mat-card-content>
                    <div class="user-card">
                    <div>
                        <span class="headerText">{{card?.jar_name}}</span>
                        <br>
                        <div class="portfolio-nudge-wrapper" *ngIf="card?.portfolio_nudge">
                          <span 
                            class="portfolio-nudge"
                            style="padding: 3px;"
                            [ngClass]="{'fix-investment': card?.portfolio_state === 'fix_investment'}">
                            {{card?.portfolio_nudge}}
                          </span>
                      </div>
                    </div>
                    <!-- <div class="riskType-wrapper">{{card.risk_level}}</div> -->
                    </div>
                    <!-- <div class="risk-content" style="width: 65%;">Portfolio has been rebalanced</div> -->
                    <div><img src="../../../../assets/dashboardHRIconMobile.svg" alt=""></div>
                    <div class="matcard-content" style="display: flex; flex-direction: column;">
                      <div class="row">
                        <div class="col-6">
                          <div class="headerText">Invested Amount</div>
                          <div class="headData" style="font-size: 15px;"
                          [ngStyle]="{ color: card?.investment_amount < 0 ? '#E17466' : '#0D7816' }"
                          ><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{card?.investment_amount | decimalFormat}}</div>
                      </div>
                      <div class="col-6">
                          <div class="headerText">Current Investment</div>
                          <div class="headData" style="font-size: 15px;"
                          [ngStyle]="{ color: card?.current_value < 0 ? '#E17466' : '#0D7816' }"
                          ><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{card?.current_value | decimalFormat}}</div>
                      </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-6">
                            <div class="headerText">Total Returns</div>
                            <div class="headData" style="font-size: 15px;"
                            [ngStyle]="{ color: card?.performance < 0 ? '#E17466' : '#0D7816' }"
                            ><img 
                            [src]="card?.performance < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'"
                            alt="">&nbsp;{{ card?.performance }} %</div>
                        </div>
                        <div class="col-6">
                          <div class="headerText">Day's P&L</div>
                          <div class="headData" style="font-size: 15px;"
                          [ngStyle]="{ color: card?.daily_profit_loss_percentage < 0 ? '#E17466' : '#0D7816' }"
                          ><img 
                          [src]="card?.daily_profit_loss_percentage < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'"
                          alt="">&nbsp;{{ card?.daily_profit_loss_percentage }} %</div>
                        </div>
                      </div>
                    </div>
                    <div><img src="../../../../assets/dashboardHRIconMobile.svg" alt=""></div>
                    <div style="display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px">
                    <app-button style="margin-right: 10px;" [savingsText]="'Basket Details'" [backgroundColor]="'transparent'" [color]="'#2A62D6'" (click)="investMoreExistingUser('Basket Details', card, getBtnText(card))"></app-button>
                    <app-button [savingsText]="getBtnText(card)" [backgroundColor]="btnColorExistingUser" (click)="investMoreExistingUser(getBtnText(card), card, getBtnText(card))"></app-button>
                    </div>
                
                </mat-card-content>
            </mat-card>
         </div>
    </div>
    <!--  -->
    </div>
    <div class="center" style="align-items: center; height: 100vh;" *ngIf="!isData">
        <img src="../../../../assets/Empty_State_No Data_Found.svg" alt="">
    </div>
</div>
<!--  -->


