<div class="row footer-wrapper">
<div style="padding: 30px; padding-bottom: 0px;">
<div class="header-wrapper" *ngIf="screenType !== 'mob'" style="display: flex; justify-content: space-between;">
    <div class="content">
        <div><img src="../../../../assets/multiplyRR_logo_footer.svg" alt=""></div>
        <div class="headerSubText">
            <span class="pointerTxt" style="cursor: pointer;" (click)="callPhoneNumber('+91 22 61013800/42')">
            <img src="../../../../assets/callIcon_footer.svg" alt="">&nbsp;&nbsp;&nbsp;&nbsp;+91 22 61013800/42</span>
        </div>
        <div class="headerSubText">
            <span><img src="../../../../assets/emailIcon_footer.svg" alt="">&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="mailto: support@equentis.com" class="pointerTxt" style="cursor: pointer; text-decoration:none; color: white; text-decoration: underline;">
            support.multiplyrr&#64;equentis.com
        </a></span>
        </div>
        <div class="headerSubText">
            <span><img src="../../../../assets/location_footer.svg" alt="">&nbsp;&nbsp;&nbsp;&nbsp;
            <span class="pointerTxt">Equentis Wealth Advisory Services Limited
                <br>
            <span style="font-size: 14px; color: #D1D2E2;">
                Marathon Futurex, A-603 6th Floor, Mafatlal<br> Mills Compound, N M Joshi Marg, Lower Parel<br> East, Mumbai 400013
            </span>
            </span>
            </span>
        </div>
    </div>
    <div>
        <div class="headerText">Company</div>
        <div class="headerSubText" (click)="goToPrivacyPolicy('Privacy Policy')">Privacy Policy</div>
        <div class="headerSubText" (click)="goToWebsiteDisclaimer('Website Disclaimer')">Website Disclaimer</div>
        <!-- <div class="headerSubText" (click)="goToNews('Terms & conditions')">Terms & conditions</div> -->
        <!-- <div class="headerSubText" (click)="goToDiscover('Refund policy')">Refund policy</div> -->
        <div class="headerSubText" (click)="goToContactUs('Contact us')">Contact us</div>
        <div class="headerSubText" (click)="goToPrivacyPolicy('Disclaimer')">Disclaimer</div>
        <div class="headerSubText" (click)="goToTermsOfService('Terms of service')">Terms of service</div>
        <div class="headerSubText" (click)="goToRegulatoryDisclaimer('Regulatory disclaimer')">Regulatory disclaimer</div>
        <div class="headerSubText" (click)="goToAboutUs('About Us')">About Us</div>
    </div>
<div>
    <div class="headerText">Explore Goals</div>
    <div *ngFor="let data of categoryList">
        <div class="headerSubText" (click)="goToBasket(data.category_id)">{{data.title}}</div>
    </div>
</div>
<div>
    <div class="headerText">Quick Links</div>
    <!-- <div class="headerSubText" (click)="goToPerformance('Performance')">Performance</div> -->
    <div class="headerSubText" (click)="goToBlogs('Blogs')">Blogs</div>
    <div class="headerSubText" (click)="goToEliteClub('Elite Club Conclave')">Elite Club Conclave</div>
    <div class="headerSubText" (click)="goToMagzine('Magazine')">Magazine</div>
    <div class="headerSubText" (click)="goToMedia('Media and PR')">Media and PR</div>
    <div class="headerSubText" (click)="goToVideo('Video Gallery')">Video Gallery</div>
    <div class="headerSubText" (click)="goToNewsletters('Newsletters')">Newsletters</div>
    <div class="headerSubText" (click)="goToStockScreener('Stock Screener')">Stock Screener</div>
    <div class="headerSubText" (click)="goToIPO('IPOs')">IPOs</div>
    <div class="headerSubText" (click)="goToFinancialCalculators('Financial Calculators')">Financial Calculators</div>
</div>
<div>
    <div class="headerText">Equentis Wealth Advisory Services</div>
    <div class="headerSubText" (click)="onPrivacyPolicy('Equentis - Research & Ranking', 'researchandranking')">Equentis - Research & Ranking</div>
    <div class="headerSubText" (click)="onPrivacyPolicy('Equentis - Private Wealth', 'epw')">Equentis - Private Wealth</div>
    <div class="headerSubText" (click)="onPrivacyPolicy('Equentis - Informed InvestoRR', 'informedinvestorr/home')">Equentis - Informed InvestoRR</div>
    <!-- <div class="headerSubText" (click)="onPrivacyPolicy('Equentis - MultiplyRR')">Equentis - MultiplyRR</div> -->
    <div class="headerText">Follow us 
        <br>
        <div class="follow-us">
        <span><img src="../../../../assets/facebook.svg" alt="" (click)="goToFacebook('facebookClicked')"></span>
        <span><img src="../../../../assets/twitter.svg" alt="" (click)="goToTwitter('twitterClicked')"></span>
        <span><img src="../../../../assets/linkedIn.svg" alt="" (click)="goToLinkedIn('linkedInClicked')"></span>
        <span><img src="../../../../assets/instagram.svg" alt="" (click)="goToInstagram('instagramClicked')"></span>
        <span><img src="../../../../assets/youTube.svg" alt="" (click)="goToYouTube('youTubeClicked')"></span>
        <span><img src="../../../../assets/teleGram.svg" alt="" (click)="goToTeleGram('telegramClicked')"></span>
        </div>
        <br>
        <br>
        <div style="display: flex; gap: 10px;">
            <div>
                <img src="../../../../assets/download_button_playStore.svg" alt="" (click)="openPlayStore()" style="cursor: pointer;">
            </div>
            <div>
                <img src="../../../../assets/download_button_appStore.svg" alt="" (click)="openAppStore()" style="cursor: pointer;">
            </div>
        </div>
    </div>
</div>
</div>
<div class="header-wrapper" *ngIf="screenType == 'mob'">
    <div class="content">
        <div><img src="../../../../assets/multiplyRR_logo_footer.svg" alt=""></div>
        <div class="headerSubText">
            <span class="pointerTxt" style="cursor: pointer;" (click)="callPhoneNumber('+91 22 61013800/42')">
            <img src="../../../../assets/callIcon_footer.svg" alt="">&nbsp;&nbsp;&nbsp;&nbsp;+91 22 61013800/42</span>
        </div>
        <div class="headerSubText">
            <span><img src="../../../../assets/emailIcon_footer.svg" alt="">&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="mailto: support@equentis.com" class="pointerTxt" style="cursor: pointer; text-decoration:none; color: white; text-decoration: underline;">
            support.multiplyrr&#64;equentis.com
        </a></span>
        </div>
        <div class="headerSubText">
            <span><img src="../../../../assets/location_footer.svg" alt="">&nbsp;&nbsp;&nbsp;&nbsp;
            <span class="pointerTxt">Equentis Wealth Advisory Services Limited
                <br>
            <span style="font-size: 14px; color: #D1D2E2;">
                Marathon Futurex, A-603 6th Floor, Mafatlal<br> Mills Compound, N M Joshi Marg, Lower Parel<br> East, Mumbai 400013
            </span>
            </span>
            </span>
        </div>
    </div>
    <br><br>
    <div style="display: flex; justify-content: space-between;">
    <div>
        <div class="headerText">Company</div>
        <div class="headerSubText" (click)="goToPrivacyPolicy('Privacy Policy')">Privacy Policy</div>
        <div class="headerSubText" (click)="goToWebsiteDisclaimer('Website Disclaimer')">Website Disclaimer</div>
        <!-- <div class="headerSubText" (click)="goToNews('Terms & conditions')">Terms & conditions</div> -->
        <!-- <div class="headerSubText" (click)="goToDiscover('Refund policy')">Refund policy</div> -->
        <div class="headerSubText" (click)="goToContactUs('Contact us')">Contact us</div>
        <div class="headerSubText" (click)="goToPrivacyPolicy('Disclaimer')">Disclaimer</div>
        <div class="headerSubText" (click)="goToTermsOfService('Terms of service')">Terms of service</div>
        <div class="headerSubText" (click)="goToRegulatoryDisclaimer('Regulatory disclaimer')">Regulatory disclaimer</div>
        <div class="headerSubText" (click)="goToAboutUs('About Us')">About Us</div>
    </div>
    <div>
        <div class="headerText">Explore Goals</div>
        <div *ngFor="let data of categoryList">
            <div class="headerSubText" (click)="goToBasket(data.category_id)">{{data.title}}</div>
        </div>
    </div>
    </div>
</div>
<div class="header-wrapper" *ngIf="screenType == 'mob'">
    <div style="display: flex; justify-content: space-between;">
        <div>
            <div class="headerText">Quick Links</div>
            <!-- <div class="headerSubText" (click)="goToPerformance('Performance')">Performance</div> -->
            <div class="headerSubText" (click)="goToBlogs('Blogs')">Blogs</div>
            <div class="headerSubText" (click)="goToEliteClub('Elite Club Conclave')">Elite Club Conclave</div>
            <div class="headerSubText" (click)="goToMagzine('Magazine')">Magazine</div>
            <div class="headerSubText" (click)="goToMedia('Media and PR')">Media and PR</div>
            <div class="headerSubText" (click)="goToVideo('Video Gallery')">Video Gallery</div>
            <div class="headerSubText" (click)="goToNewsletters('Newsletters')">Newsletters</div>
            <div class="headerSubText" (click)="goToStockScreener('Stock Screener')">Stock Screener</div>
            <div class="headerSubText" (click)="goToIPO('IPOs')">IPOs</div>
            <div class="headerSubText" (click)="goToFinancialCalculators('Financial Calculators')">Financial Calculators</div>
        </div>
    <div>
        <div class="headerText">Equentis Wealth Advisory Services</div>
        <div class="headerSubText" (click)="onPrivacyPolicy('Equentis - Research & Ranking', 'researchandranking')">Equentis - Research & Ranking</div>
        <div class="headerSubText" (click)="onPrivacyPolicy('Equentis - Private Wealth', 'epw')">Equentis - Private Wealth</div>
        <div class="headerSubText" (click)="onPrivacyPolicy('Equentis - Informed InvestoRR', 'informedinvestorr/home')">Equentis - Informed InvestoRR</div>
        <!-- <div class="headerSubText" (click)="onPrivacyPolicy('Equentis - MultiplyRR')">Equentis - MultiplyRR</div> -->
        <div class="headerText">Follow us
            <br>
            <div class="follow-us">
                <span><img src="../../../../assets/facebook.svg" alt="" (click)="goToFacebook('facebookClicked')"></span>
                <span><img src="../../../../assets/twitter.svg" alt="" (click)="goToTwitter('twitterClicked')"></span>
                <span><img src="../../../../assets/linkedIn.svg" alt="" (click)="goToLinkedIn('linkedInClicked')"></span>
                <span><img src="../../../../assets/instagram.svg" alt="" (click)="goToInstagram('instagramClicked')"></span>
                <span><img src="../../../../assets/youTube.svg" alt="" (click)="goToYouTube('youTubeClicked')"></span>
                <span><img src="../../../../assets/teleGram.svg" alt="" (click)="goToTeleGram('telegramClicked')"></span>
                </div>
            <br>
            <br>
                <div>
                    <img src="../../../../assets/download_button_playStore.svg" alt="" (click)="openPlayStore()">
                </div>
                <br>
                <div>
                    <img src="../../../../assets/download_button_appStore.svg" alt="" (click)="openAppStore()">
                </div>
        </div>
    </div>
</div>
</div>
<br>
<img width="100%" src="../../../../assets/hrTagFooter.svg" alt="">
<br>
<br>
<div class="row">
    <div class="col-12" style="color: white;">
        SEBI Registered Investment Advisor Details:
    </div>
</div>
<div class="row header-wrapper" style="margin-top: 20px;">
<div class="col-12">
    <span class="headerSubText_Sebi">
    <span class="headerText_Sebi">Registered name:</span>Equentis Wealth Advisory Services  Limited,
    <span class="headerText_Sebi">SEBI Registration No.</span>: INA000003874 <span class="headerText_Sebi">Type of registration</span>: Non-Individual <span class="headerText_Sebi">Validity</span>: Dec 08, 2015 – Perpetual <span class="headerText_Sebi">BSE Enlistment No</span>: 1816
    <span class="headerText_Sebi">CIN</span>: U74999MH2015PLC262812 <span class="headerText_Sebi">Registered office address</span>: Marathon Futurex, A-603 6th Floor, Mafatlal Mills Compound,N. M. Joshi Marg, Lower Parel, Mumbai 400013.
    <span class="headerText_Sebi">Telephone No.</span>: <span style="cursor: pointer; text-decoration: underline;" (click)="callPhoneNumber('+91 22 61013800')">+91 22 61013800</span> <span class="headerText_Sebi"> Principal Officer</span>: Mr. Manish Goel, <span class="headerText_Sebi">Email</span>: <a href="mailto:  po@equentis.com" class="pointerTxt" style="cursor: pointer; text-decoration:underline; color: #A8A8A8;">
        po&#64;equentis.com</a>. <span class="headerText_Sebi">Telephone No.</span>: <span style="cursor: pointer; text-decoration: underline;" (click)="callPhoneNumber('+91 22 61013800')">+91 22 61013800</span>
    <span class="headerText_Sebi"> Compliance officer</span>: Mr. Rakesh Gupta, <span class="headerText_Sebi">Email</span>: <a href="mailto:  compliance@equentis.com" class="pointerTxt" style="cursor: pointer; text-decoration:underline; color: #A8A8A8;">
        compliance&#64;equentis.com</a>, <span class="headerText_Sebi">Telephone No.</span>: <span style="cursor: pointer; text-decoration: underline;" (click)="callPhoneNumber('+91 22 61013800')">+91 22 61013800</span> <span class="headerText_Sebi">&nbsp;Grievance officer</span>:  
    Mr. Swapnil Patil, <span class="headerText_Sebi">Email</span>: 
    <a href="mailto:  grievance@equentis.com" class="pointerTxt" style="cursor: pointer; text-decoration:underline; color: #A8A8A8;">
     grievance&#64;equentis.com</a>, <span class="headerText_Sebi">Telephone No.</span>: <span style="cursor: pointer; text-decoration: underline;" (click)="callPhoneNumber('+91 22 61013800')">+91 22 61013800</span> <span class="headerText_Sebi">
    SCORES</span>: <a href="https://www.scores.gov.in/" style="color: #A8A8A8; cursor: pointer; text-decoration:underline" target="_blank">https://www.scores.gov.in/</a>
    </span>
</div>
</div>
<br>
<div class="row">
    <div class="col-12" style="color: white;">
        SEBI Office Details:
    </div>
</div>
<div class="row header-wrapper" style="margin-top: 20px;">
<div class="col-12">
    <span class="headerSubText_Sebi">
    <span class="headerText_Sebi">SEBI Bhavan BKC</span>
    <br>
    <span class="headerText_Sebi">Address:</span> Plot No.C4-A, 'G' Block Bandra-Kurla Complex, Bandra (East), Mumbai - 400051, Maharashtra 
    <span class="headerText_Sebi">Tel</span>: +91-22-26449000 / 40459000 | <span class="headerText_Sebi">Fax</span>:
    +91-22-26449019-22 / 40459019-22 <span class="headerText_Sebi">Email</span>: 
    <a href="mailto:  sebi@sebi.gov.in" class="pointerTxt" style="cursor: pointer; text-decoration:underline; color: #A8A8A8;">
        sebi&#64;sebi.gov.in</a>
    <span class="headerText_Sebi">Toll Free Investor Helpline: </span>: 1800 22 7575
    </span>
</div>
</div>
<br>
    <div class="row header-wrapper" style="margin-top: 20px;">
    <div class="col-12">
        <span class="headerSubText_Sebi" style="cursor: auto;">
        <span class="headerText_Sebi">Financial Calculators:</span>&nbsp;<span class="pointerEvents" (click)="routeFinancialCalculators('sip-calculator')">SIP Calculator</span>, <span class="pointerEvents" (click)="routeFinancialCalculators('cagr-calculator')">CAGR Calculator</span>, <span class="pointerEvents" (click)="routeFinancialCalculators('retirement-planning-calculator')">Retirement Planning Calculator</span>
        &nbsp;
        <span class="headerText_Sebi">IPOs</span>:  <span class="pointerEvents" (click)="routeIPO('current-new-ipo')">Current IPOs</span>, <span class="pointerEvents" (click)="routeIPO('upcoming-ipo')">Upcoming IPOs</span>, <span class="pointerEvents" (click)="routeIPO('listed-ipo')">Listed IPO</span>&nbsp;
        <span class="headerText_Sebi">Most Visited On Research and Ranking : Nifty 50 Stocks</span>:
        <span class="pointerEvents" (click)="routeStocks('adani-ports-&special_132921')" style="text-decoration: underline;">Adani Ports and SEZ</span>, <span class="pointerEvents" (click)="routeStocks('asian-paints_100820')" style="text-decoration: underline;">Asian Paints</span>,
        <span class="pointerEvents" (click)="routeStocks('bajaj-finserv_205198')" style="text-decoration: underline;">Bajaj Finserv Ltd</span>, <span class="pointerEvents" (click)="routeStocks('bharti-airtel_132454')" style="text-decoration: underline;">Bharti Airtel</span>,
        <span class="pointerEvents" (click)="routeStocks('icici-bank_132174')" style="text-decoration: underline;">ICICI Bank Share Price</span>, <span class="pointerEvents" (click)="routeStocks('tata-steel_100470')" style="text-decoration: underline;">Tata Steel Ltd</span>, 
        <span class="pointerEvents" (click)="routeStocks('mahindra-&-mahindra_100520')" style="text-decoration: underline;">Mahindra & Mahindra</span>, <span class="pointerEvents" (click)="routeStocks('kotak-mahindra-bank_100247')" style="text-decoration: underline;">Kotak Mahindra</span>, 
        <span class="pointerEvents" (click)="routeStocks('reliance-industries_100325')" style="text-decoration: underline;">Reliance Industries Limited</span>, <span class="pointerEvents" (click)="routeStocks('titan-co_100114')" style="text-decoration: underline;">Titan Company</span>,
        <span class="pointerEvents" (click)="routeStocks('tata-motors_100570')" style="text-decoration: underline;">Tata Motors</span>, <span class="pointerEvents" (click)="routeStocks('ultratech-cement_132538')" style="text-decoration: underline;">UltraTech Cement</span>, 
        <span class="pointerEvents" (click)="routeStocks('wipro_107685')" style="text-decoration: underline;">Wipro</span>, <span class="pointerEvents" (click)="routeStocks('hero-motocorp_100182')" style="text-decoration: underline;">Hero MotoCorp</span>, 
        <span class="pointerEvents" (click)="routeStocks('shree-cement_100387')" style="text-decoration: underline;">Shree Cement</span>, <span class="pointerEvents" (click)="routeStocks('nestle_100790')" style="text-decoration: underline;">Nestle India</span>, 
        <span class="pointerEvents" (click)="routeStocks('maruti-suzuki_132500')" style="text-decoration: underline;">Maruti Suzuki</span>, <span class="pointerEvents" (click)="routeStocks('hcl-tech._132281')" style="text-decoration: underline;">HCL Technologies</span>, 
        <span class="pointerEvents" (click)="routeStocks('itc_100875')" style="text-decoration: underline;">ITC Limited</span>, <span class="pointerEvents" (click)="routeStocks('bharti-airtel_132454')" style="text-decoration: underline;">Bharti Airtel</span>
        <span class="headerText_Sebi">&nbsp;Stocks</span>: <span class="pointerEvents" (click)="routeStocks('adani-green-energy_289554')">Adani Green</span>,
        <span class="pointerEvents" (click)="routeStocks('tata-power_100400')">Tata Power</span>, <span class="pointerEvents" (click)="routeStocks('central-dep.-service_207950')">Central Dep. Service</span>, 
        <span class="pointerEvents" (click)="routeStocks('yes-bank_132648')">Yes Bank</span>, <span class="pointerEvents" (click)="routeStocks('irctc_208412')">IRCTC</span>, 
        <span class="pointerEvents" (click)="routeStocks('adani-wilmar_207673')">Adani Wilmar</span>, <span class="pointerEvents" (click)="routeStocks('tata-steel_100470')">Tata Steel</span>, 
        <span class="pointerEvents" (click)="routeStocks('visa-steel_132721')">Visa steel</span>, <span class="pointerEvents" (click)="routeStocks('oil-india_200091')">Oil India</span>, 
        <span class="pointerEvents" (click)="routeStocks('bpcl_100547')">BPCL</span>, <span class="pointerEvents" (click)="routeStocks('affle-%28india%29_229238')">Affle India</span>, 
        <span class="pointerEvents" (click)="routeStocks('adani-power_209867')">Adani Power</span>, <span class="pointerEvents" (click)="routeStocks('happiest-minds-tech._284232')">Happiest Minds Technologies</span>, 
        <span class="pointerEvents" (click)="routeStocks('punjab-&-sind-bank_218058')">Punjab & Sind National Bank</span>, 
        <span class="pointerEvents" (click)="routeStocks('canara-bank_132483')">Canara Bank</span>, <span class="pointerEvents" (click)="routeStocks('central-dep.-service_207950')">CDSL</span>, 
        <span class="pointerEvents" (click)="routeStocks('yes-bank_132648')">Yes Bank</span>, <span class="pointerEvents" (click)="routeStocks('trident_121064')">Trident</span>, 
        <span class="pointerEvents" (click)="routeStocks('urja-global_126987')">Urja Global</span>, <span class="pointerEvents" (click)="routeStocks('sail_100113')">SAIL</span>
        </span>
    </div>
    </div>  
    <div class="row header-wrapper" style="margin-top: 20px; padding: 10px; background-color: #282828; width: 100vw; position: relative; left: 50%; right: 50%; margin-left: -50vw; margin-right: -50vw;">
        <span class="headerText_Sebi">Disclaimers:</span>
        <br><br>
        <span class="headerText_Sebi" style="font-size: 14px; padding-bottom: 10px;">
            “Equentis – MultiplyRR” is the brand under which Equentis Wealth Advisory Services Limited offers its Investment Advisory Services.
        </span>
        <span class="headerSubText_Sebi">
            <ul>
                <li>Investments in securities market are subject to market risks. Read all the related documents carefully before investing.</li>
                <li>The information is only for consumption by the intended recipient and such material should not be redistributed.</li>
                <li>Registration granted by SEBI, enlistment as IA with Exchange and certification from NISM in no way guarantee performance of the intermediary or provide any assurance of returns to investors.</li>
              </ul>
        </span>
        <span class="headerSubText_Sebi" style="font-size: 14px;">Copyright © 2024 Equentis Wealth Advisory Services Ltd. All Rights Reserved.</span>
    </div>
</div>
</div>