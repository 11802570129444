<app-toolbar [showUserName] = "usernameToolBar" [username] = "userName" (loginClicked)="openLogin('', '', '')"></app-toolbar>
<div class="main_wrapper_laptop" *ngIf="this.screenType.breakpointState !== 'mob'">
    <div class="overlay" *ngIf="spinner">
        <div class="spinner">
          <img src="../../../../assets/loader.svg" alt="" class="loader">
        </div>
    </div>

    <div class="cards-wrapper">
    <div class="header-background">
        <span>Stock baskets for your needs</span>
        <span class="sub-header-text">Baskets curated based on your risk profile and investment horizon</span>
    </div>
    <br>
    <img src="../../../../assets/basket_hr_tag.svg" alt="" width="100%">
    <br>
    <br>
    <mat-accordion>
        <div *ngFor="let data of basketList; let i = index" class="expansion-panel-wrapper">
            <mat-expansion-panel #panel hideToggle [expanded]="i === 0"
            (opened)="onPanelToggle(data, true)" (closed)="onPanelToggle(data, false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="content-wrapper">
                            <span class="risk-data">
                            <span>{{ data?.sub_product_name }} &nbsp;
                            <span class="risk-wrapper" *ngIf="data?.pb_details?.risk_level" [ngStyle]="{
                                'background-color': data?.pb_details?.risk_level === 'MEDIUM' ? '#F9F5F1' : 
                                                    data?.pb_details?.risk_level === 'LOW' ? '#E4F3E3' : 
                                                    data?.pb_details?.risk_level === 'HIGH' ? '#FFECED' : '',
                                                    'display': 'inline-block',
                                                    'padding': '0.2rem 0.5rem',
                                                    'white-space': 'nowrap'
                              }">
                            <span class="risk-text" [ngStyle]="{
                                'color': data?.pb_details?.risk_level === 'MEDIUM' ? '#9B6F49' : 
                                         data?.pb_details?.risk_level === 'LOW' ? '#0AC01C' : 
                                         data?.pb_details?.risk_level === 'HIGH' ? '#FF4347' : ''
                              }">
                                {{data?.pb_details?.risk_level | camelCase}} Risk
                            </span>
                            </span>
                            </span>
                           </span>
                            <span class="sub-header">{{ data?.product_description }}</span>
                        </div>
                    </mat-panel-title>
                    <mat-panel-description>
                        <div style="display: flex; justify-content: space-around; align-items: center; width: 100%;">
                            <div class="content-wrapper">
                                <span>Minimum Investment</span>
                                <span style="font-weight: 800; color: #001E5A;">
                                    <i class="fa fa-rupee"></i>&nbsp;{{ data?.pb_details?.min_investment_amount ?? 0 }}
                                </span>
                            </div>
                            <img src="../../../../assets/hrVertical.svg" alt="" *ngIf="!panel.expanded">
                            <img src="../../../../assets/hrVertical.svg" alt="" style="margin-top: 25px;" *ngIf="panel.expanded">
                            <div class="content-wrapper">
                                <span>Investment period</span>
                                <span style="font-weight: 800; color: #001E5A;">
                                    {{ data?.investment_horizon }}
                                </span>
                            </div>
                            <!-- <div class="content-wrapper">
                                <span>3Y CAGR</span>
                                <span [ngClass]="{ 'blurred': !isLoggedIn }" style="color: #5BCD65; font-weight: 600;">
                                    <img src="../../../../assets/upwardArrowIcon.svg" alt="">
                                    {{ data?.pb_details?.cagr ?? 0 }} %
                                </span>
                            </div> -->
                            <div>
                                <div class="pricing-content" *ngIf="!data?.is_subscribed">
                                    Basket Price: 
                                    <span class="price-value">
                                    <i class="fa fa-rupee"></i>&nbsp;{{ data?.fees_details[0].details.advisory_fees | readableNumber }}
                                    </span>
                                    <br>
                                    <span class="gst-text">Exclusive of all taxes</span>
                                </div>
                                <div class="button-position">
                                <app-button 
                                [savingsText]="data?.is_getting_renewed 
                                              ? 'Renew Now' 
                                              : !data?.is_subscribed && !data?.is_getting_onboard
                                              ? 'Subscribe Now' 
                                              : !data?.is_subscribed && data?.is_getting_onboard
                                              ? 'Continue'
                                              : data?.portfolio_state === 'fix_investment' 
                                              ? 'Fix Investment' 
                                              : data?.portfolio_state === 'rebalance_now' 
                                              ? 'Rebalance Now' 
                                              : data?.portfolio_state === 'invest_now' 
                                              ? 'Invest Now'
                                              : data?.portfolio_state === 'invest_more' 
                                              ? 'Invest More' 
                                              : data?.portfolio_state === 'rebalance_in_progress' 
                                              ? 'Rebalancing...' 
                                              : data?.portfolio_state === 'order_in_progress' 
                                              ? 'Order Pending'
                                              : 'No Action Available'" 
                                class="center"
                                [backgroundColor]=" data?.portfolio_state === 'fix_investment' || data?.portfolio_state === 'rebalance_now' || data?.is_getting_renewed
                                              ? '#E17466' 
                                              : '#C3966E'"
                                (click)="subscribeBasket(data, i, data.is_subscribed); $event.stopPropagation()">
                              </app-button>
                              </div>
                            </div>
                        </div>
                        <span class="icons">
                            <img *ngIf="!panel.expanded" src=".././../../../assets/collapse_down.svg" alt="">
                            <img *ngIf="panel.expanded" src=".././../../../assets/collapse_up.svg" alt="">
                        </span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                    <img src="../../../../assets/hrDarkTagIcon.svg" alt="">
                </div>
                <div class="row mat-expansion-panel-body" style="margin-top: 50px;">
                    <div class="col-7">
                        <div class="more-details-wrapper">
                            <div *ngFor="let detail of data.product_more_details" class="detail-item">
                              <h3 class="detail-title">{{ detail.title }}</h3>
                              <div class="detail-desc" [innerHTML]="detail.desc"></div>
                            </div>
                        </div>                  
                    </div>
                    <div class="col-5" style="display: flex; justify-content: flex-end;">
                        <!-- <video [src]="data?.videoBlobUrl" controls height="50%" width="100%">
                            Your browser does not support the video tag.
                        </video> -->
                        <video [src]="data?.videoBlobUrl" controls
                        controlsList="nodownload" height="50%" width="100%" [poster]="data?.image_url"
                        (play)="videoClick($event, data)"
                        (pause)="videoPaused($event, data)">
                            Your browser does not support the video tag.
                        </video> 
                    </div>
                </div>
            </mat-expansion-panel>
            <div class="svg-wrapper">
                <img src="../../../../assets/basket_icon.svg" *ngIf="!data.is_watch_listed && !data?.is_subscribed" alt="Icon" class="top-right-svg"
                (click)="addToWatchList(data, data.is_watch_listed); $event.stopPropagation()"
                matTooltip="Add to Watchlist">
                <img src="../../../../assets/basket_icon_watchlisted.svg" *ngIf="data.is_watch_listed" alt="Icon" class="top-right-svg"
                (click)="addToWatchList(data, data.is_watch_listed); $event.stopPropagation()"
                matTooltip="Watchlisted">
            </div>
        </div>
    </mat-accordion>    
    
    </div>

      

<div class="row section-wrapper" *ngIf="isDataLoaded">
    <div class="center header-text">
        <!-- <span>Simple <span class="highlighted-text">4 easy steps</span> to start your wealth creation</span> -->
        <span [innerHTML]="sanitizeHtml(homePageJSONResponse[5].h1 ? homePageJSONResponse[5].h1 : '')"></span>
    </div>
    <div class="center subheader-text">
        {{homePageJSONResponse[5].h2 ? homePageJSONResponse[5].h2 : ''}}
    </div>
    <div class="hoverSection">
        <!-- <div class="col-1"></div> -->
        <div>
            <mat-card (mouseenter)="mouseHover('signup')" class="matCard-wrapper"
            [style.transform]="isHoveredSignUp ? 'scale(1.05)' : 'scale(1)'"
              style="transition: transform 0.3s ease; transform-origin: left;">
                <mat-card-content><span class="stepsHeader" [ngClass]="{'hovered': isHoveredSignUp}">
                    <span class="stepCount" [ngClass]="{'hovered-bg': isHoveredSignUp}">1</span>
                    <span class="headerText">{{homePageJSONResponse[5].content[0].name ? homePageJSONResponse[5].content[0].name : ''}}:</span><br>
                    <span class="stepsText" [innerHTML]="homePageJSONResponse[5].content[0].detail ? homePageJSONResponse[5].content[0].detail : ''"></span></span>
                </mat-card-content>
            </mat-card>
            <br>
            <!-- <mat-card (mouseenter)="mouseHover('KYC')" class="matCard-wrapper"
            [style.transform]="isHoveredKYC ? 'scale(1.1)' : 'scale(1)'">
                <mat-card-content><span class="stepsHeader" [ngClass]="{'hovered': isHoveredKYC}"><span class="stepCount" [ngClass]="{'hovered-bg': isHoveredKYC}">2</span>&nbsp;&nbsp;&nbsp;&nbsp;{{homePageJSONResponse[5].content[1].name ? homePageJSONResponse[5].content[1].name : ''}}:&nbsp;</span>
                <span class="stepsText">{{homePageJSONResponse[5].content[1].detail ? homePageJSONResponse[5].content[1].detail : ''}}</span></mat-card-content>
            </mat-card>
            <br> -->
            <mat-card (mouseenter)="mouseHover('Subscribe')" class="matCard-wrapper"
            [style.transform]="isHoveredSubscribe ? 'scale(1.05)' : 'scale(1)'"
              style="transition: transform 0.3s ease; transform-origin: left;">
                <mat-card-content><span class="stepsHeader" [ngClass]="{'hovered': isHoveredSubscribe}">
                <span class="stepCount" [ngClass]="{'hovered-bg': isHoveredSubscribe}">2</span>
                <span class="headerText">{{homePageJSONResponse[5].content[2].name ? homePageJSONResponse[5].content[2].name : ''}}:</span><br></span>
                <span class="stepsText" [innerHTML]="homePageJSONResponse[5].content[2].detail ? homePageJSONResponse[5].content[2].detail : ''"></span></mat-card-content>
            </mat-card>
            <br>
            <mat-card (mouseenter)="mouseHover('Invest')" class="matCard-wrapper"
            [style.transform]="isHoveredInvest ? 'scale(1.05)' : 'scale(1)'"
              style="transition: transform 0.3s ease; transform-origin: left;">
                <mat-card-content><span class="stepsHeader" [ngClass]="{'hovered': isHoveredInvest}">
                <span class="stepCount" [ngClass]="{'hovered-bg': isHoveredInvest}">3</span>
                <span class="headerText">{{homePageJSONResponse[5].content[3].name ? homePageJSONResponse[5].content[3].name : ''}}:</span><br></span>
                <span class="stepsText" [innerHTML]="homePageJSONResponse[5].content[3].detail ? homePageJSONResponse[5].content[3].detail : ''"></span></mat-card-content>
            </mat-card>
        </div>
        <div>
            <!-- add steps GIF here -->
            <img src="../../../../assets/Step_1.svg" style="max-height: none;" alt="" *ngIf="isSignup">
            <img src="../../../../assets/subscribe.svg" style="max-height: none;" alt="" *ngIf="isKYC">
            <img src="../../../../assets/3_Invest.svg" style="max-height: none;" alt="" *ngIf="isInvest">
        </div>
        <!-- <div class="col-1"></div> -->
    </div>
</div>
<div class="row section-wrapper" style="margin-left: 20px;" *ngIf="isDataLoaded && basketPageJSONResponse">
    <div class="center header-text" style="padding-top: 30px; font-size: 36px; font-weight: 400;">
        <span [innerHTML]="sanitizeHtml(basketPageJSONResponse[2]?.h1 ? basketPageJSONResponse[2]?.h1 : '')"></span>
    </div>
    <div class="center subheader-text">
        {{basketPageJSONResponse[2]?.h2 ? basketPageJSONResponse[2]?.h2 : ''}}
    </div>
    <br>
    <br>
    <div class="row">
        <br>
        <br>
        <div style="display: flex; justify-content: space-between; margin-top: 50px;">
        <div>
        <div class="row insideSection-wrapper-desk">
            <span class="center header-text">
                <img src="../../../../assets/rebalacing.svg" alt="">
                <br>
                {{basketPageJSONResponse[2]?.content[0]?.name ? basketPageJSONResponse[2]?.content[0]?.name : ''}}
            </span>
            <span class="center sub-text" style="margin-top: 20px;"
            [innerHTML]="sanitizeHtml(basketPageJSONResponse[2]?.content[0]?.detail ? basketPageJSONResponse[2]?.content[0]?.detail : '')">
            </span>
        </div>
        <div class="row insideSection-wrapper-desk">
            <span class="center header-text">
                <img src="../../../../assets/portfolioUpdates.svg" alt="">
                <br>
                {{basketPageJSONResponse[2]?.content[2]?.name ? basketPageJSONResponse[2]?.content[2]?.name : ''}}
            </span>
            <span class="center sub-text" style="margin-top: 20px;"
            [innerHTML]="sanitizeHtml(basketPageJSONResponse[2]?.content[2]?.detail ? basketPageJSONResponse[2]?.content[2]?.detail : '')"
            ></span>
        </div>
        </div>
        <div>
            <div class="row insideSection-wrapper-desk">
                <span class="center header-text">
                    <img src="../../../../assets/oneClickInvestment.svg" alt="">
                    <br>
                    {{basketPageJSONResponse[2]?.content[1]?.name ? basketPageJSONResponse[2]?.content[1]?.name : ''}}
                </span>
                <span class="center sub-text" style="margin-top: 20px;"
                [innerHTML]="sanitizeHtml(basketPageJSONResponse[2]?.content[1]?.detail ? basketPageJSONResponse[2]?.content[1]?.detail : '')"
                ></span>
            </div>
        <div class="row insideSection-wrapper-desk">
            <span class="center header-text">
                <img src="../../../../assets/detailedResearchReport.svg" alt="">
                <br>
                {{basketPageJSONResponse[2]?.content[3]?.name ? basketPageJSONResponse[2]?.content[3]?.name : ''}}
            </span>
            <span class="center sub-text" style="margin-top: 20px;"
            [innerHTML]="sanitizeHtml(basketPageJSONResponse[2]?.content[3]?.detail ? basketPageJSONResponse[2]?.content[3]?.detail : '')"
            ></span>
        </div>
        </div>
        </div>
    </div>
</div>
<!-- <div class="row faqSection" *ngIf="isDataLoaded">
    <div class="col-1"></div>
    <div class="col-10 background">
        <span style="font-weight: 600; font-size: 20px; font-family: 'manrope'; display: flex; justify-content: center;">Have more questions or some feedback?</span>
        <br>
        <span style="font-weight: 500; font-size: 18px; color: #667085;" class="center">Can’t find the answer you’re looking for? Please connect with our support team.</span>
        <app-button [savingsText]="'Request a call back'" class="center" style="margin-top: 30px;" [backgroundColor]="'#001E5A'"></app-button>
    </div>
</div> -->
<app-footer-curve *ngIf="isDataLoaded"></app-footer-curve>
<app-footer *ngIf="isDataLoaded"></app-footer>
</div>

<div class="main_wrapper_mobile" *ngIf="this.screenType.breakpointState == 'mob'" style="margin-bottom: 70px; overflow-x: hidden;">
    <div class="overlay" *ngIf="spinner">
        <div class="spinner">
          <img src="../../../../assets/loader.svg" alt="" class="loader">
        </div>
    </div>
    <app-toolbar-mobile></app-toolbar-mobile>
    <div *ngFor="let data of basketList; let i = index" style="background-color: #001E5A;">
        <div class="future-industry-wrapper"[style.background-color]="i % 2 !== 0 ? 'white' : ''">
            <div class="row">
                <div class="col-12">
                    <div class="header-text" style="color: white;" [style.color]="i % 2 !== 0 ? 'black' : 'white'">
                        {{data?.sub_product_name}}
                        <!-- <span class="riskTypeText" [style.background-color]="i % 2 !== 0 ? '#F5D16099' : '#BBA14F99'"
                        [style.color]="i % 2 !== 0 ? '#BC920B' : '#FFC200'">{{data.pb_details.risk_level + ' ' + 'RISK'}}</span> -->
                        <span class="risk-wrapper"
                        style="display: inline-block;
                        word-wrap: break-word;"
                        *ngIf="data?.pb_details?.risk_level" [ngStyle]="{
                            'background-color': data?.pb_details?.risk_level === 'MEDIUM' ? '#F9F5F1' : 
                                                data?.pb_details?.risk_level === 'LOW' ? '#E4F3E3' : 
                                                data?.pb_details?.risk_level === 'HIGH' ? '#FFECED' : ''
                          }">
                        <span class="risk-text" [ngStyle]="{
                            'color': data?.pb_details?.risk_level === 'MEDIUM' ? '#9B6F49' : 
                                     data?.pb_details?.risk_level === 'LOW' ? '#0AC01C' : 
                                     data?.pb_details?.risk_level === 'HIGH' ? '#FF4347' : ''
                          }">
                            {{data?.pb_details?.risk_level | camelCase}} Risk
                        </span>
                        </span>
                    </div>
                    <div class="row" style="margin-top: 10px;">
                       <span style="font-size: 14px;" [style.color]="i % 2 !== 0 ? 'black' : 'white'">{{data?.product_description}}</span> 
                    </div>
                </div>
            </div>
            <div class="row industryContainerMobile" [ngStyle]="{ 'color': i % 2 === 0 ? '#C6C6C6' : '', 'background-color': i % 2 !== 0 ? '#E9E9E9' : '' }" style="width: 100%; margin-left: 0px; margin-top: 10px;">
                <div style="display: flex; justify-content: space-between;">
                <!-- <div>                    
                <div>3Y CAGR</div>
                <div [ngClass]="{ 'blurred': !isLoggedIn }" style="color: #5BCD65; font-weight: 600;"><img src="../../../../assets/upwardArrowIcon.svg" alt=""> {{ data?.pb_details?.cagr ?? 0 }} %</div>
                </div> -->
                <div style="display: flex; justify-content: flex-start;">
                    <div>
                        <div>Investment period</div>
                        <div style="font-weight: 800;" [style.color]="i % 2 !== 0 ? 'black' : 'white'">
                            {{ data?.investment_horizon }}
                        </div>
                    </div>
                </div>
                <div>                 
                    <div>Min Investment</div>
                    <div><i class="fa fa-rupee"></i> &nbsp;<span style="font-weight: 800;" [style.color]="i % 2 !== 0 ? 'black' : 'white'">{{data?.pb_details?.min_investment_amount ?? 0}}</span></div>
                </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div style="display: flex; 
                justify-content: center;
                flex-direction: column;
                align-items: center;
                gap: 25px;">
                    <div><app-button *ngIf="!data?.is_subscribed" [savingsText]="data?.is_watch_listed ? 'Watchlisted' : 'Add to Watchlist'" [isTransparentBTN]="true" (click)="addToWatchList(data,data.is_watch_listed)"></app-button></div>
                    <div><app-button 
                        [savingsText]="data?.is_getting_renewed 
                        ? 'Renew Now' 
                        : !data?.is_subscribed && !data?.is_getting_onboard
                        ? 'Subscribe Now' 
                        : !data?.is_subscribed && data?.is_getting_onboard
                        ? 'Continue'
                        : data?.portfolio_state === 'fix_investment' 
                        ? 'Fix Investment' 
                        : data?.portfolio_state === 'rebalance_now' 
                        ? 'Rebalance Now' 
                        : data?.portfolio_state === 'invest_now' 
                        ? 'Invest Now'
                        : data?.portfolio_state === 'invest_more' 
                        ? 'Invest More' 
                        : data?.portfolio_state === 'rebalance_in_progress' 
                        ? 'Rebalancing...' 
                        : data?.portfolio_state === 'order_in_progress' 
                        ? 'Order Pending'
                        : 'No Action Available'"
                        [backgroundColor]="data?.portfolio_state === 'fix_investment' || data?.portfolio_state === 'rebalance_now' || data?.is_getting_renewed ? '#E17466' : '#C3966E'"
                        (click)="subscribeBasket(data, i, data.is_subscribed)"></app-button></div>
                </div>
            </div>
            <div>
                <ng-container *ngIf="i % 2 === 0">
                <img src="../../../../assets/hrMobIcon.svg" alt="">
                </ng-container>
                <ng-container *ngIf="i % 2 !== 0">
                    <img src="../../../../assets/hrDarkMobIcon.svg" alt="">
                </ng-container>
            </div>
            <!-- <div style="background-color: #001E5A;" [style.background-color]="i % 2 !== 0 ? 'transparent' : '#001E5A'"><span style="font-size: 14px; font-weight: 600; font-family: 'manrope';" [style.color]="i % 2 !== 0 ? 'black' : 'white'">Benchmark Comparision :</span>
                <div style="display: flex; justify-content: flex-start; padding-top: 14px;" [style.background-color]="i % 2 !== 0 ? 'transparent' : '#001E5A'">
                    <span style="font-size: 12px;" [style.color]="i % 2 !== 0 ? 'black' : 'white'"><img src="../../../../assets/basketCompareMark.svg" alt="">&nbsp;&nbsp;{{data.pb_details.benchmark_comparison.jar_name}}</span>
                    &nbsp;
                    <span style="font-size: 12px;" [style.color]="i % 2 !== 0 ? 'black' : 'white'"><img src="../../../../assets/nifty50Mark.svg" alt="">&nbsp;&nbsp;{{data.pb_details.benchmark_comparison.benchmark_name}}</span>
                </div>
                <div class="chartFilter" style="border: 1px; margin-top: 10px;">
                    <div [class.selected]="selectedFilter === '1 Year'" (click)="selectFilter('1 Year')">1 Year</div>
                    <div [class.selected]="selectedFilter === '3 Year'" (click)="selectFilter('3 Year')">3 Year</div>
                    <div [class.selected]="selectedFilter === '5 Year'" (click)="selectFilter('5 Year')">5 Year</div>
                </div>
                </div>
                <br>
                  <div class="chart-container">
                    <div class="chart-wrapper" [class.blurred]="!isLoggedIn">
                      <highcharts-chart
                        [Highcharts]="Highcharts"
                        [options]="chartOptions[i]"
                        style="width: 100%; height: 350px; display: block;">
                      </highcharts-chart>
                    </div>
                  
                    <div *ngIf="!isLoggedIn" class="popup-container">
                      <div class="popup-content">
                        <img src="../../../../assets/lockedIcon.svg" alt="">
                        <p>Login to see the live performance and return</p>
                        <app-button (click)="openLogin('')" [savingsText]="'Login'"></app-button>
                      </div>
                    </div>
                  </div> -->
                <div style="display: flex; align-items: center;">
                    <video [src]="data?.videoBlobUrl" controls
                    controlsList="nodownload" height="100%" width="100%" style="margin-top: 45px;" [poster]="data?.image_url"
                    (play)="videoClick($event, data)"
                    (pause)="videoPaused($event, data)">
                        Your browser does not support the video tag.
                    </video> 
                </div>
        </div>
        </div>
        <!-- <div class="row faqSection" *ngIf="isDataLoaded">
            <div class="col-1"></div>
            <div class="col-10 background">
                <span style="font-weight: 600; font-size: 20px; font-family: 'manrope'; display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;">Have more questions or some feedback?</span>
                <br>
                <span style="font-weight: 500; font-size: 18px; color: #667085;" class="center">Can’t find the answer you’re looking for? Please connect with our support team.</span>
                <app-button [savingsText]="'Request a call back'" class="center" style="margin-top: 30px;" [backgroundColor]="'#001E5A'"></app-button>
            </div>
        </div> -->
        <app-footer *ngIf="isDataLoaded"></app-footer>
</div>