import { ApplicationConfig, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material/material.module';
import { CoreRoutingModule } from './core-routing.module';
import { ToolbarComponent } from '../layout/toolbar/toolbar.component';
import { ToolbarMobileComponent } from '../layout/toolbar-mobile/toolbar-mobile.component';
import { FooterComponent } from '../layout/footer/footer.component';
import { HomeComponent } from '../components/home/home.component';
import { ButtonComponent } from '../shared/elements/button/button.component';
import { LoginComponent } from '../components/auth/login/login.component';
import { LoginMobileViewComponent } from '../components/auth/login-mobile-view/login-mobile-view.component';
import { InputComponent } from '../shared/elements/input/input.component';
import { PinBoxComponent } from '../shared/elements/pin-box/pin-box.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BasketsComponent } from '../components/baskets/baskets.component';
import { RiskProfileQuestionsComponent } from '../components/risk-profile-questions/risk-profile-questions.component';
import { ListComponent } from '../shared/common/list/list.component';
import { FooterCurveComponent } from '../shared/common/footer-curve/footer-curve.component';
import { TitleComponent } from '../shared/common/title/title.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { NotificationsComponent } from '../layout/notifications/notifications.component';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { RiskProfileResultComponent } from '../components/risk-profile-result/risk-profile-result.component';
import { PanComponent } from '../components/pan/pan.component';
import { DatepickerComponent } from '../shared/elements/datepicker/datepicker.component';
import { InfoComponent } from '../shared/elements/info/info.component';
import { InsightsTabComponent } from '../components/insights-tab/insights-tab.component';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ReBalanceHistoryComponent } from '../components/rebalance-history/rebalance-history.component';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { OnlyCharacterDirective } from '../shared/directives/only-character.directive';
import { InvestementModalDashboardComponent } from '../components/investement-modal-dashboard/investement-modal-dashboard.component';
import { PurchaseHistoryComponent } from '../components/purchase-history/purchase-history.component';
import { BankDetailsComponent } from '../components/bank-details/bank-details.component';
import { FatcaComponent } from '../components/fatca/fatca.component';
import { AgreementComponent } from '../components/agreement/agreement.component';
import { PdfViewerComponent } from '../components/agreement/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ReadableNumberPipe } from '../shared/pipes/readable-number.pipe';
import { DropdownComponent } from '../shared/elements/dropdown/dropdown.component';
import { DecimalFormatPipe } from '../shared/pipes/decimal-format.pipe';
import { PlanSelectionComponent } from '../shared/dialogs/plan-selection/plan-selection.component';
import { FormatSelectDataPipe } from '../shared/pipes/format-select-data.pipe';
import { TimeAgoPipe } from '../shared/pipes/time-ago.pipe';
import { HighchartsChartModule } from 'highcharts-angular';
import { DiscoverComponent } from '../components/discover/discover.component';
import { WatchlistComponent } from '../components/watchlist/watchlist.component';
import { SelectComponent } from '../shared/elements/select/select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchIFSCComponent } from '../shared/dialogs/search-ifsc/search-ifsc.component';
import { NotificationListComponent } from '../components/notification-list/notification-list.component';
import { ProfileComponent } from '../components/profile/profile.component';
import { BasketMoreDetailsComponent } from '../components/basket-more-details/basket-more-details.component';
import { TransactionDetailsComponent } from '../components/transaction-details/transaction-details.component';
import { UserAlreadyExistsComponent } from '../shared/dialogs/user-already-exists/user-already-exists.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { CamelCasePipe } from '../shared/pipes/camel-case.pipe';
import { UserDeleteComponent } from '../components/user-delete/user-delete.component';
import { AmoConfirmationComponent } from '../shared/dialogs/amo-confirmation/amo-confirmation.component';
import { OrderInProgressComponent } from '../shared/dialogs/order-in-progress/order-in-progress.component';
import { BasketDetailsComponent } from '../components/basket-details/basket-details.component';
@NgModule({
  declarations: [
    ToolbarComponent,
    NotificationsComponent,
    ToolbarMobileComponent,
    HomeComponent,
    BasketsComponent,
    RiskProfileQuestionsComponent,
    ButtonComponent,
    InputComponent,
    PinBoxComponent,
    SelectComponent,
    FooterComponent,
    LoginComponent,
    LoginMobileViewComponent,
    ListComponent,
    FooterCurveComponent,
    TitleComponent,
    RiskProfileResultComponent,
    DashboardComponent,
    PanComponent,
    DatepickerComponent,
    InfoComponent,
    InsightsTabComponent,
    ReBalanceHistoryComponent,
    OnlyCharacterDirective,
    InvestementModalDashboardComponent,
    PurchaseHistoryComponent,
    BankDetailsComponent,
    FatcaComponent,
    AgreementComponent,
    PdfViewerComponent,
    ReadableNumberPipe,
    DecimalFormatPipe,
    FormatSelectDataPipe,
    TimeAgoPipe,
    DropdownComponent,
    PlanSelectionComponent,
    UserAlreadyExistsComponent,
    DiscoverComponent,
    WatchlistComponent,
    SearchIFSCComponent,
    NotificationListComponent,
    ProfileComponent,
    BasketMoreDetailsComponent,
    TransactionDetailsComponent,
    PageNotFoundComponent,
    CamelCasePipe,
    UserDeleteComponent,
    AmoConfirmationComponent,
    OrderInProgressComponent,
    BasketDetailsComponent,
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    HttpClientModule,
    CanvasJSAngularChartsModule,
    PdfViewerModule,
    HighchartsChartModule,
    NgSelectModule,
    ToastrModule.forRoot(),
  ],
  exports: [
    InputComponent,
    PinBoxComponent,
    SelectComponent,
    DatepickerComponent,
    InfoComponent,
    FormsModule,
    ReactiveFormsModule,
    ReadableNumberPipe,
    DecimalFormatPipe,
    FormatSelectDataPipe,
    TimeAgoPipe,
    TitleComponent,
    ButtonComponent,
    CamelCasePipe,
  ],
})
export class CoreModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSpinner);
    library.addIcons(faEdit);
  }
}
