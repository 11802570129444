import { Component, EventEmitter, OnInit, Output, HostListener } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { LoginComponent } from '../auth/login/login.component';
import { Router } from '@angular/router';
import { ScreenBreakpointService } from '../../services/screen-breakpoint.service';
import { ApiServiceService } from '../../services/api-service.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangeDetectorRef } from '@angular/core';
import { format, isToday, isYesterday } from 'date-fns';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrl: './notification-list.component.scss',
})
export class NotificationListComponent implements OnInit {
  usernameToolBar: any;
  userName: any;
  spinner: boolean = false;
  @Output() updateBadgeCount: EventEmitter<number> = new EventEmitter<number>();
  matBadgeCount: number = 0;
  notificationsList: any[] = [];
  groupedNotifications = {};
  isAllRead: boolean = true;
  limit: number = 20;
  offset: number = 0;
  page: number = 1;
  isLoading: boolean = false;
  id: number = 1;
  customer_id: any;
  device_id: any;
  isDataLoaded: boolean = false;
  customSort = (a: KeyValue<string, any[]>, b: KeyValue<string, any[]>): number => {
    const today = new Date();
    
    // Handling 'Today' and 'Yesterday'
    if (a.key === 'Today') return -1;
    if (b.key === 'Today') return 1;
    if (a.key === 'Yesterday') return -1;
    if (b.key === 'Yesterday') return 1;
  
    // Convert keys (dates) into actual Date objects for comparison
    const dateA = new Date(a.key);
    const dateB = new Date(b.key);
  
    // Compare dates to sort in ascending order
    return dateB.getTime() - dateA.getTime();
  };
  

  constructor(
    private route: Router,
    public screenType: ScreenBreakpointService,
    private apiService: ApiServiceService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {

  }

  ngOnInit(): void {
    let customerId: any;
    let token = '';
    token = localStorage.getItem('authToken') || '';
    if (token) {
      const jsonString = this.apiService.decodeToken(token.split('.')[1]);
      const data = JSON.parse(jsonString);
      console.log('ngOnInit token data', data);
      customerId = data.customer_id;
      this.customer_id = customerId;
      this.device_id = data.deviceId;
      const name = data?.name || data?.full_name || '';
      this.usernameToolBar = true;
      this.userName = name;
      this.getNotificationList(this.customer_id, this.page);
    } else {
      this.openLogin('');
    }
    scroll(0, 0);
  }

  onScroll(event: any) {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight && !this.isLoading) {
      this.page++;
      this.getNotificationList(this.id, this.page);
    }
  }

  openLogin(data: string) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    if (isMobileView) {
      let data = {
        customData: 'notification',
      };
      let navigationExtras: NavigationExtras = {
        state: {
          customData: data,
        },
      };
      this.route.navigate(['login'], navigationExtras);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: false,
        width: isMobileView ? '100%' : '100%',
      });
    }
  }

  markAsRead(id: any, isDelivered: any) {
  if(!isDelivered) {
    this.apiService.markNotificationAsRead(id, this.device_id).subscribe((res: any) => {
      console.log(res);
      if(res && res.success) {
      if(res.data.message == 'marked') {
        this.getNotificationList(this.customer_id, this.page);
        const notification = this.notificationsList.find(notification => notification._id === id);
        console.log(notification);
          if (notification) {
            notification.isRead = true;
          }
      }
      }
    });
  }
  }

  markAllAsRead() {
    this.apiService.markAllNotificationAsRead(this.customer_id).subscribe((res: any) => {
      console.log(res);
      if(res && res.success) {
        this.getNotificationList(this.customer_id, this.page);
      }
    })
  }

  getNotificationList(id: any, page: number) {
    this.isLoading = true;
    this.apiService.getNotificationList(id, page).subscribe((res: any) => {
      if (res && res.success) {
        this.notificationsList = res.data.list;
        console.log('this.notificationsList', this.notificationsList );
        this.groupedNotifications = this.groupNotificationsByDate();
        console.log('this.notificationsList', this.notificationsList)
        this.matBadgeCount = res.data.undeliveredMsgCount;
        this.updateBadgeCount.emit(this.matBadgeCount);
      }
      this.isDataLoaded = true;
      this.isLoading = false;
    });
   }

   groupNotificationsByDate() {
    const today = new Date();
    
    const grouped = this.notificationsList.reduce((acc, notification) => {
      const createdAt = new Date(notification.createdAt);
      
      let groupKey: string;
      
      if (isToday(createdAt)) {
        groupKey = 'Today';
      } else if (isYesterday(createdAt)) {
        groupKey = 'Yesterday';
      } else {
        groupKey = format(createdAt, 'MMMM d, yyyy');
      }
      
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      
      acc[groupKey].push(notification);
      return acc;
    }, {});
    
    return grouped;
  }
 
   deleteNotification(data: string) {
     const index = this.notificationsList.indexOf(data);
     if (index !== -1) {
       this.notificationsList.splice(index, 1);
     }
     this.matBadgeCount = this.notificationsList.length;
     this.updateBadgeCount.emit(this.matBadgeCount);
   }
 
   deleteAllNotifications() {
     this.notificationsList = [];
     this.isAllRead = false;
     this.matBadgeCount = 0;
     this.updateBadgeCount.emit(this.matBadgeCount);
   }

}
