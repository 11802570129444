import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenTypeService } from '../../services/screen-type.service';
import {
  DomSanitizer,
  Meta,
  SafeHtml,
  SafeResourceUrl,
  SafeUrl,
  Title,
} from '@angular/platform-browser';
import { ApiServiceService } from '../../services/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from '../auth/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { OnboardingService } from '../../services/onboarding.service';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MoengageService } from '../../services/moengage.service';
import { UserAlreadyExistsComponent } from '../../shared/dialogs/user-already-exists/user-already-exists.component';

@Component({
  selector: 'app-baskets',
  templateUrl: './baskets.component.html',
  styleUrls: ['./baskets.component.scss'],
})
export class BasketsComponent implements OnInit {
  selectedFilter: string = '3 Year';
  basketPageJSONResponse: any;
  usernameToolBar: boolean = false;
  userName: any;
  videoUrl: any;
  categoryId: any;
  basketList: any[] = [];
  isDataLoaded: boolean = false;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Array<Highcharts.Options> = [];
  isLoggedIn: boolean = false;
  isInvest: boolean = false;
  spinner: boolean = false;
  homePageJSONResponse: any;
  categoriesList: any[] = [];
  visibleItems: any;
  loadedIndexes: boolean[] = [];
  hoveredIndex = -1;
  currentTime: number = 0;
  private eventTriggered: boolean = false;
  categoryImage: any;
  isInitialLoad: boolean = true;
  currentVideo: HTMLVideoElement | null = null;
  isAtStart: boolean = true;
  isAtEnd: boolean = false;

  selectFilter(filter: string): void {
    this.selectedFilter = filter;
  }

  chart: any;

  chartOptionsDark = {
    animationEnabled: true,
    theme: 'light2',
    title: {
      text: '',
    },
    axisX: {
      valueFormatString: 'D MMM',
    },
    axisY: {
      title: '',
    },
    toolTip: {
      shared: true,
    },
    legend: {
      cursor: 'pointer',
      itemclick: function (e: any) {
        if (
          typeof e.dataSeries.visible === 'undefined' ||
          e.dataSeries.visible
        ) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        e.chart.render();
      },
    },
    backgroundColor: '#001E5A',
    data: [
      {
        type: 'line',
        showInLegend: false,
        color: '#C3966E',
        name: 'Growth and Momentum engines',
        xValueFormatString: 'MMM DD, YYYY',
        dataPoints: [
          { x: new Date(2021, 8, 1), y: 63 },
          { x: new Date(2021, 8, 2), y: 69 },
          { x: new Date(2021, 8, 3), y: 65 },
          { x: new Date(2021, 8, 4), y: 70 },
          { x: new Date(2021, 8, 5), y: 71 },
          { x: new Date(2021, 8, 6), y: 65 },
          { x: new Date(2021, 8, 7), y: 73 },
          { x: new Date(2021, 8, 8), y: 86 },
          { x: new Date(2021, 8, 9), y: 74 },
          { x: new Date(2021, 8, 10), y: 75 },
          { x: new Date(2021, 8, 11), y: 76 },
          { x: new Date(2021, 8, 12), y: 84 },
          { x: new Date(2021, 8, 13), y: 87 },
          { x: new Date(2021, 8, 14), y: 76 },
          { x: new Date(2021, 8, 15), y: 79 },
        ],
      },
      {
        type: 'line',
        showInLegend: false,
        color: '#7E8B99',
        name: 'Nifty 50',
        dataPoints: [
          { x: new Date(2021, 8, 1), y: 60 },
          { x: new Date(2021, 8, 2), y: 57 },
          { x: new Date(2021, 8, 3), y: 51 },
          { x: new Date(2021, 8, 4), y: 56 },
          { x: new Date(2021, 8, 5), y: 54 },
          { x: new Date(2021, 8, 6), y: 55 },
          { x: new Date(2021, 8, 7), y: 54 },
          { x: new Date(2021, 8, 8), y: 69 },
          { x: new Date(2021, 8, 9), y: 65 },
          { x: new Date(2021, 8, 10), y: 66 },
          { x: new Date(2021, 8, 11), y: 63 },
          { x: new Date(2021, 8, 12), y: 67 },
          { x: new Date(2021, 8, 13), y: 66 },
          { x: new Date(2021, 8, 14), y: 56 },
          { x: new Date(2021, 8, 15), y: 64 },
        ],
      },
    ],
  };

  isSignup: boolean = true;
  isKYC: boolean = false;
  isHoveredSignUp: boolean = true;
  isHoveredKYC: boolean = false;
  isHoveredSubscribe: boolean = false;
  isHoveredInvest: boolean = false;
  tokenData: any;
  productCode: any;
  constructor(
    public screenType: ScreenTypeService,
    private sanitizer: DomSanitizer,
    private apiService: ApiServiceService,
    private _toastSerivce: ToastrService,
    public dialog: MatDialog,
    private activated_route: ActivatedRoute,
    private route: Router,
    private user_service: UserService,
    private onboardingService: OnboardingService,
    private moengageService: MoengageService,
    private meta: Meta,
    private title: Title
  ) {
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  extractVideoId(): string {
    const videoIdMatches = this.videoUrl.match(/(?:\?|&)v=([a-zA-Z0-9_-]+)/);
    return videoIdMatches && videoIdMatches.length > 1 ? videoIdMatches[1] : '';
  }

  getEmbedUrl(getUrl: any): SafeResourceUrl {
    this.videoUrl = getUrl;
    const videoId = this.extractVideoId();
    const url = `https://www.youtube.com/embed/${videoId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  initializeChartOptions(data: any, index: number) {
    console.log('chart data', data);
    this.chartOptions[index] = {
        chart: {
            type: 'areaspline',
            backgroundColor: ((index) % 2) !== 0 ? 'white' : '#001E5A',
        },
        title: {
            text: '',
            align: 'left'
        },
        xAxis: {
            categories: data?.benchmark_comparison.axis_date,
            title: {
                text: ''
            },
            labels: {
              formatter () {
                return `<span style="color: #757575">${this.value}</span>`
              }
            },
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
              formatter () {
                return `<span style="color: #757575">${this.value}</span>`
              }
            },
            gridLineColor: '#757575',
        },
        tooltip: {
            shared: true,
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            areaspline: {
              fillOpacity: 0.1,
            }
        },
        series: [
            {
                name: data?.benchmark_comparison.jar_name,
                data: data?.benchmark_comparison.axis_jar,
                type: 'areaspline',
                color: '#E49944'
            },
            {
                name: data?.benchmark_comparison.benchmark_name,
                data: data?.benchmark_comparison.axis_benchmark,
                type: 'areaspline',
                color: '#7E8B99'
            }
        ],
        legend: {
          enabled: false
      }
    };
  }

  addToWatchList(data: any, isWatchlisted: any) {
    if(this.tokenData) {
      if(isWatchlisted) {
        this.apiService.removeWatchList(data.watch_id).subscribe((res: any) => {
          if(res && res.success == true) {
          this._toastSerivce.success('Removed from Watchlist');
          let eventData = {
            'BasketName' : data?.sub_product_name,
            'BasketPrice': data?.fees_details[0]?.details?.advisory_fees,
            'VideoThumbnailUrl': data?.image_url,
            'VideoUrl': data?.videoBlobUrl,
            'CategoryName' : localStorage.getItem('categoryNameMoe') || '',
            'BasketId' : data?.sub_product_id,
            'MinInvestmentAmount' : data?.pb_details?.min_investment_amount,
            'BasketRisk' : data?.pb_details?.risk_level,
            'Returns' : data?.pb_details?.cagr ?? '0',
            'ReturnsDuration' : data?.investment_horizon
          }
          this.moengageService.watchlistRemoved(eventData);
          this.getBasketList(this.categoryId);
          }
          else {
            this._toastSerivce.error('Failed to remove from Watchlist');
          }
        })
      }
      else {
        let payload = {
          sub_product_id: data.sub_product_id
        }
        this.apiService.addToWatchList(payload).subscribe((res: any) => {
          console.log(res);
          if(res && res.success == true) {
            this._toastSerivce.success('Added to Watchlist');
            let eventData = {
              'BasketName' : data?.sub_product_name,
              'BasketPrice': data?.fees_details[0]?.details?.advisory_fees,
              'VideoThumbnailUrl': data?.image_url,
              'VideoUrl': data?.videoBlobUrl,
              'CategoryName' : localStorage.getItem('categoryNameMoe') || '',
              'BasketId' : data?.sub_product_id,
              'MinInvestmentAmount' : data?.pb_details?.min_investment_amount,
              'BasketRisk' : data?.pb_details?.risk_level,
              'Returns' : data?.pb_details?.cagr ?? '0',
              'ReturnsDuration' : data?.investment_horizon
            }
            this.moengageService.watchlistAdded(eventData);
            this.getBasketList(this.categoryId);
          }
          else {
            this._toastSerivce.error('Failed to add Watchlist!');
          }
        });
      }
    }
    else {
      this.openLogin('', '', '');
    }
  }

  replaceBreakLine(data: any) {
    return data.replace('<br>', '');
  }

  onImageLoad(index: number) {
    this.loadedIndexes[index] = true;
  }

  onImageError(index: number) {
    // Handle error case if needed
    console.error(`Image at index ${index} failed to load.`);
  }

  hoverCard(index: number) {
    this.hoveredIndex = index;
  }

  unhoverCard(index: number) {
    if (this.hoveredIndex === index) {
      this.hoveredIndex = -1;
    }
  }

  scroll(direction: number) {
    const track = document.querySelector('.carousel-track') as HTMLElement | null;
    if (track) {
      const card = track.querySelector('.carousel-card') as HTMLElement | null;
      if (card) {
        const cardWidth = card.clientWidth + 18; // Card width + gap
        track.scrollLeft += direction * cardWidth * 3; // Scroll by 3 cards at a time

        // Update flags to check if at start or end
        this.isAtStart = track.scrollLeft === 0;
        this.isAtEnd = track.scrollLeft + track.clientWidth >= track.scrollWidth;
      }
    }
  }

  goToBaskets(data: any) {
    console.log(data);
    this.route.navigate(['baskets'], { queryParams: { category_id: data } });
    scroll(0,0);
  }

  scrollTop() {
    scroll(0,0);
  }

  addMetaTags(categoryId: any) {
    if(categoryId == '1') {
      this.title.setTitle('Own Your Dream Car by Investing In Stocks | Equentis - Multiplyrr');
      this.meta.addTags([
        { name: 'description', content: 'Looking to buy your dream car and save money for that downpayment? Invest in our customized stock baskets to fulfill your goal. Just 3-steps to get started. Visit now!' }
      ]);
    }
    else if(categoryId == '2') {
      this.title.setTitle('Create Passive Income by Investing In Stocks | Equentis - Multiplyrr');
      this.meta.addTags([
        { name: 'description', content: 'Want to create passive income to manage expenses? Invest in our customized stock baskets to fulfill your goal. Just 3-steps to get started. Visit now!' }
      ]);
    }
    else if(categoryId == '3') {
      this.title.setTitle('Become an Entrepreneur by Investing In Stocks | Equentis - Multiplyrr');
      this.meta.addTags([
        { name: 'description', content: 'Dream of setting up your own business and becoming an entrepreneur? Invest in our customized stock baskets to fulfill your goal. Just 3-steps to get started. Visit now!' }
      ]);
    }
    else if(categoryId == '4') {
      this.title.setTitle('Create Long-Term Wealth by Investing In Stocks | Equentis - Multiplyrr');
      this.meta.addTags([
        { name: 'description', content: 'Want to create sustainable wealth? Invest in our customized stock baskets to fulfill your goal. Just 3-steps to get started. Visit now!' }
      ]);
    }
    else if(categoryId == '5') {
      this.title.setTitle('Buy Your Own House by Investing In Stocks | Equentis - Multiplyrr');
      this.meta.addTags([
        { name: 'description', content: 'Buying a house in your favorite city or building a home in your native village? Invest in our customized stock baskets to fulfill your goal. Just 3-steps to get started. Visit now!' }
      ]);
    }
    else if(categoryId == '6') {
      this.title.setTitle('Plan an Early Retirement by Investing In Stocks | Equentis - Multiplyrr');
      this.meta.addTags([
        { name: 'description', content: 'Not a fan of retiring at 60? Then, plan your early retirement today. Invest in our customized stock baskets to fulfill your goal. Just 3-steps to get started. Visit now!' }
      ]);
    }
  }

  ngOnInit() {
    // get category id from home page
    this.activated_route.queryParams.subscribe(params => {
      this.categoryId = params['category_id'];
      this.addMetaTags(this.categoryId);
      console.log(this.categoryId);
      this.getBasketList(this.categoryId);
      
    });
    //
    let token = '';
    token = localStorage.getItem('authToken') || '';
    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
    }
    if(token && data?.is_other_product_active) {
      const dialogRef = this.dialog.open(UserAlreadyExistsComponent, {
        height: 'auto',
        disableClose: true,
        autoFocus: false,
        backdropClass: 'backdropBackground',
        width: 'auto',
      });
    }
    else if(token && data.customer_meta_info.is_registered) {
      this.tokenData = data;
      console.log('ngOnInit token data', data);
      const name = data?.name || data?.full_name || '';
      this.usernameToolBar = true;
      this.userName = name;
      this.isLoggedIn = true;
    }
    else {
      this.isLoggedIn = false;
    }
    this.getBasketPageResponse();
    this.getHomePageData();
  }

  setCurrentTime(event: any, data: any) {
    this.currentTime = event.target.currentTime;
    if(!this.eventTriggered && this.currentTime >= 10) {
      let eventData = {
        'BasketName' : data.sub_product_name,
        'VideoUrl' : data.videoBlobUrl,
        'WatchedDuration': Math.round(this.currentTime),
        'VideoThumbnailUrl' : data.image_url,
        'ActiveSubscription' : data.is_subscribed
        }
        this.moengageService.videoWatched(eventData);
        this.eventTriggered = true;
    }
  }

  videoPaused(event: any, data: any) {
    const videoElement = event.target as HTMLVideoElement;
    if (this.currentVideo === videoElement) {
      this.currentVideo = null;
    }
    const watchedDuration = Math.round(event.target.currentTime);
    if(watchedDuration > 10) {
      let eventData = {
        'CategoryName': localStorage.getItem('categoryNameMoe') || '',
        'BasketName' : data.sub_product_name,
        'VideoUrl' : data.videoBlobUrl,
        'VideoThumbnailUrl' : data.image_url,
        'ActiveSubscription' : data.is_subscribed,
        'WatchedDuration': Math.round(event.target.currentTime),
        'BasketRisk': data?.pb_details?.risk_level,
        'MinInvestmentAmount': data?.pb_details?.min_investment_amount,
        'ReturnsDuration': data?.investment_horizon,
        'BasketPrice': data?.fees_details[0]?.details?.advisory_fees,
        'Returns': data?.pb_details?.cagr,
        }
      this.moengageService.videoWatched(eventData);
    }
  }

  videoClick(event: any, data: any) {
    const videoElement = event.target as HTMLVideoElement;
    if (this.currentVideo && this.currentVideo !== videoElement) {
      this.currentVideo.pause();
    }
    this.currentVideo = videoElement;
    const time = Math.round(event.target.currentTime);
    if(time == 0) {
      let eventData = {
        'CategoryName': localStorage.getItem('categoryNameMoe') || '',
        'BasketName' : data.sub_product_name,
        'VideoUrl' : data.videoBlobUrl,
        'VideoThumbnailUrl' : data.image_url,
        'ActiveSubscription' : data.is_subscribed,
        'BasketRisk': data?.pb_details?.risk_level,
        'MinInvestmentAmount': data?.pb_details?.min_investment_amount,
        'ReturnsDuration': data?.investment_horizon,
        'BasketPrice': data?.fees_details[0]?.details?.advisory_fees,
        'Returns': data?.pb_details?.cagr,
        }
        this.moengageService.basketVideoPlay(eventData);
    }
  }

  getBasketList(categoryId: any) {
    this.apiService.getBasketData(categoryId).subscribe((res: any) => {
      console.log(res);
      if(res.success) {
        this.isInitialLoad = true;
        this.basketList = res.data;
        this.isDataLoaded = true;
        localStorage.setItem('categoryNameMoe', this.basketList[0]?.title);

        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].pb_details) {
              this.initializeChartOptions(res.data[i].pb_details, i);
          } else {
              this.chartOptions = [];
          }
      }
      
        let basketNames = []
        let activeSubscription = false;
        for(let data of this.basketList) {
          data.videoBlobUrl = `${environment.apiUrlFiles}/file/${data.video_url}`;
          data.image_url = `${environment.apiUrlFiles}/file/${data.image_url}`;
          basketNames.push(data.sub_product_name);
          if(data?.is_subscribed){
            activeSubscription = true
          }
        //   this.apiService.getImagefromId(data.video_url).pipe(
        //     retry(3),
        //     catchError((error) => {
        //       console.error('API call failed after 3 retries', error);
        //       return [];
        //     })
        //   ).subscribe((res: Blob) => {
        //     const videoUrl = URL.createObjectURL(res);
        //     data.videoBlobUrl = this.sanitizer.bypassSecurityTrustUrl(videoUrl);
        //   }
        // );
        }

        const categoryClickCalled = localStorage.getItem('categoryClickCalled');
        if (!categoryClickCalled) {
          let categoryDetails = {
            categoryName: this.basketList?.[0]?.title,
            categoryImage: this.categoryImage,
            categoryOverview: this.basketList?.[0]?.description,
            activeSubscription,
            basketNames,
          }
          //calling meongage event to register
          this.moengageService.categoryClick(categoryDetails);
          //
          localStorage.setItem('categoryClickCalled', 'true');
        }
        
      }
      else {
        this._toastSerivce.error('Failed to load basket details!');
      }
    })
  }

  getHomePageData() {
    this.apiService.homePageService().subscribe((res: any) => {
      if (res.success) {
        this.homePageJSONResponse = res.data.result;
        //get categories list
        this.apiService.getCategoriesList().subscribe((res: any) => {
        if(res && res.success) {
          this.categoriesList = res.data;
          this.visibleItems = res.data;
          this.visibleItems.forEach((_: any, index: any) => {
            this.loadedIndexes[index] = false;
          });
          for(let data of res.data) {
          // calling to get category image for Moe event
          if(data?.category_id == this.categoryId) {
            this.apiService.getImagefromId(data.image_url).subscribe((res: any) => {
              this.categoryImage = URL.createObjectURL(res);
            });
          }
          //
          //calling api to get real image
          this.apiService.getImagefromId(data.image_url).subscribe((res: any) => {
          data.image_url = URL.createObjectURL(res);
          })
          //
          }
        }
        });
      }
    });
  }

  onPanelToggle(data: any, isExpanded: boolean) {
    if (this.isInitialLoad) {
      this.isInitialLoad = false;
      return;
    }

    if (isExpanded) {
      let eventData = {
        'BasketName': data?.sub_product_name,
        'CategoryName': localStorage.getItem('categoryNameMoe') || '',
        'BasketOverview': data?.product_description,
        'BasketRisk': data?.pb_details?.risk_level,
        'MinInvestmentAmount': data?.pb_details?.min_investment_amount,
        'ReturnsDuration': data?.pb_details?.investment_horizon,
        'BasketPrice': data?.fees_details[0]?.details?.advisory_fees,
        'Returns': data?.pb_details?.cagr
      };
      this.moengageService.buttonClickedEvents(eventData);
    }
  }

  convertToCamelCase(input: string): string {
    return input
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  subscribeBasket(data: any, index: any, is_subscribed: any) {
    console.log('get basket details .......',index, data);
    console.log(this.tokenData);
    if(this.tokenData) {
    if(is_subscribed && !data?.is_getting_renewed) {
    this.route.navigate(['dashboard']);
    }
    else if(is_subscribed && data?.is_getting_renewed) {
      this.spinner = true;
      let payload = {
        "contact_id": this.tokenData?.contact_id,
        "sub_product_id": data?.sub_product_id
      }
      let authToken = localStorage.getItem('authToken');
      this.onboardingService.renewOnboarding(payload, authToken).subscribe((res: any) => {
        this.spinner = false;
        console.log('lead creation api res..', res);
        if(res && res.success && Object.keys(res.data).length != 0) {
            const parts = res.data.url.split('/');
            const id = parts[parts.length - 1];
            this.user_service.pushHashKey(id);
    
            // call redis api to save data
            let payload = {
              id: this.user_service.getHashKey(),
              data: {
                categoryId: data.category_id,
                subProductId: data.sub_product_id,
                onboardingId: res.data.onboarding_id,
                productCode: data.sub_product_code,
              }
            }
            this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
              console.log('got redis es..',res);
            })
            //
    
            this.route.navigate(['pan', id]);
        }
        else {
          this._toastSerivce.error('Unable to fetch onboarding details, please try again!');
        }
      },
      (err) => {
        this.spinner = false;
      }
      );
      //
      }
    else {
    this.spinner = true;
    //call moengage subscribeNow event
    let eventDetails = {
      'basketName': data?.sub_product_name,
      'categoryName': data?.title,
      'basketId': data?.category_id,
      'minInvestmentAmount': data?.pb_details?.min_investment_amount,
      'returns': data?.pb_details?.cagr || 0,
      'returnsDuration': data?.investment_horizon,
      'basketrisk': data?.pb_details?.risk_level,
      'basketprice': data?.fees_details[0]?.details?.advisory_fees
    }
    this.moengageService.subscribeNowEvent(eventDetails);
    //
    //call create onboarding API
    let payload = {
      "lead_id": this.tokenData.lead_id,
      "sub_product_id": data.sub_product_id,
      "country_id": this.tokenData.country_id === null ? 0 : this.tokenData.country_id,
      "state_id": this.tokenData.state_id === null ? 0 : this.tokenData.state_id,
      "complimentary_period": 0,
      "fees_details": data.fees_details[0],
      "first_year_amount": data.fees_details[0].details.max_yearly_amount,
      "advisory_discount_amount": 0,
      "immediate_advisory_payment_amount": data.fees_details[0].details.advisory_fees,
      "advisory_installment_option": data.advisory_installment_option[0],
      "advisory_number_of_installments": data.advisory_installment_option.length,
      "advisory_is_emandate": false,
      "advisory_emandate_date": moment().format('YYYY-MM-DD'),
      "advisory_emandate_discount": 0,
      "email_contact_detail_id": this.tokenData.email_contact_detail_id,
      "whatsapp_contact_detail_id": this.tokenData.mobile_contact_detail_id,
      "sms_contact_detail_id": this.tokenData.mobile_contact_detail_id
    }
    let authToken = localStorage.getItem('authToken');
    this.onboardingService.createOnboarding(payload, authToken).subscribe((res: any) => {
      this.spinner = false;
      console.log('lead creation api res..', res);
      if(res && res.success && Object.keys(res.data).length != 0) {
          const parts = res.data.url.split('/');
          const id = parts[parts.length - 1];
          this.user_service.pushHashKey(id);
  
          // call redis api to save data
          let payload = {
            id: this.user_service.getHashKey(),
            data: {
              categoryId: this.categoryId,
              subProductId: data.sub_product_id,
              onboardingId: res.data.onboarding_id,
              productCode: data.sub_product_code,
            }
          }
          this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
            console.log('got redis es..',res);
          })
          //
  
          this.route.navigate(['pan', id]);
      }
      else {
        this._toastSerivce.error('Unable to fetch onboarding details, please try again!');
      }
    },
  (err) => {
    this.spinner = false;
  }
  );
    //
    }
    }
    else {
      this.openLogin(data, index, is_subscribed);
    }
  }

  getBasketPageResponse() {
    this.apiService.basketPageService().subscribe((res: any) => {
      if (res.success && res.data.message == 'success') {
        this.basketPageJSONResponse = res.data.result;
      } else {
        this._toastSerivce.error('something went wrong');
      }
    });
  }

  openLogin(data: any, index: any, is_subscribed: any) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    var subscribeType = '';
    if(data !== '') {
      subscribeType = 'discover';
    }
    else {
      subscribeType = '';
    }
    if (isMobileView) {
      let data_mobile = {
        customData: 'discover',
        subscribeType: subscribeType,
        data: data,
        index: index,
        is_subscribed: is_subscribed,
      };
      let navigationExtras: NavigationExtras = {
        state: {
          customData: data_mobile,
        },
      };
      this.route.navigate(['login'], navigationExtras);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: true,
        width: isMobileView ? '100%' : '100%',
        data: {
          data: data,
          index: index,
          is_subscribed: is_subscribed,
          subscribeType: subscribeType
        },
      });
    }
  }

  mouseHover(evt: any) {
    if (evt == 'KYC') {
      this.isHoveredKYC = true;
      this.isHoveredSignUp = false;
      this.isHoveredSubscribe = false;
      this.isHoveredInvest = false;
      this.isKYC = true;
      this.isSignup = false;
      this.isInvest = false; 
    } else if (evt == 'signup') {
      this.isHoveredSignUp = true;
      this.isHoveredKYC = false;
      this.isHoveredSubscribe = false;
      this.isHoveredInvest = false;
      this.isKYC = false;
      this.isSignup = true;
      this.isInvest = false;
    } else if (evt == 'Subscribe') {
      this.isHoveredSubscribe = true;
      this.isHoveredSignUp = false;
      this.isHoveredKYC = false;
      this.isHoveredInvest = false;
      this.isKYC = true;
      this.isSignup = false;
      this.isInvest = false;
    } else {
      this.isHoveredInvest = true;
      this.isHoveredSignUp = false;
      this.isHoveredKYC = false;
      this.isHoveredSubscribe = false;
      this.isSignup = false;
      this.isKYC = false;
      this.isInvest = true;
    }
  }
}
