import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiServiceService } from '../../services/api-service.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @Output() updateBadgeCount: EventEmitter<number> = new EventEmitter<number>();
  matBadgeCount: number = 0;
  notificationsList: any[] = [];
  customer_id: any;
  device_id: any;

  constructor(
    private apiService: ApiServiceService,
    private user_service: UserService,
    private router: Router,
  ) {

  }

  ngOnInit() {
    let token = '';
    let customerId: any;
    token = localStorage.getItem('authToken') || '';
    if (token) {
      const jsonString = this.apiService.decodeToken(token.split('.')[1]);
      const data = JSON.parse(jsonString);
      console.log('ngOnInit token data', data);
      this.customer_id = data.customer_id;
      customerId = data.customer_id;
      this.device_id = data.deviceId;
    }
    this.getNotificationList(customerId);
  }

  getNotificationList(id: any) {
    this.apiService.getNotificationList(id, 1).subscribe((res: any) => {
      if (res && res.success) {
        this.matBadgeCount = res.data.undeliveredMsgCount;
        this.updateBadgeCount.emit(this.matBadgeCount);
        this.notificationsList = res.data.list;
        let parsedNotificationsList = [];
          for (let i = 0; i < 3 && i < this.notificationsList?.length; i++) {
            let data = this.notificationsList[i];
            let message = data.data.title;
            parsedNotificationsList.push(message);
          }
        this.notificationsList = parsedNotificationsList;

        console.log('notification list...', this.notificationsList);
      }

      // this.matBadgeCount = this.notificationsList.length;
      // this.updateBadgeCount.emit(this.matBadgeCount);
    })
  }

  markAllAsRead() {
    this.apiService.markAllNotificationAsRead(this.customer_id).subscribe((res: any) => {
      console.log(res);
      if (res && res.success) {
        this.getNotificationList(this.customer_id);
      }
    })
  }

  viewAllNotification() {
    this.router.navigate(['notification']);
  }
}
