import { Component, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from 'src/app/core/services/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { ScreenTypeService } from 'src/app/core/services/screen-type.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { OnboardingService } from 'src/app/core/services/onboarding.service';
import * as moment from 'moment';
import { MoengageService } from 'src/app/core/services/moengage.service';

@Component({
  selector: 'app-login-mobile-view',
  templateUrl: './login-mobile-view.component.html',
  styleUrls: ['./login-mobile-view.component.scss'],
})
export class LoginMobileViewComponent implements OnInit {
  isMobileSuffix: boolean = false;
  isNameSuffix: boolean = false;
  isEmailSuffix: boolean = false;
  termsCondition: boolean = false;
  termsData: boolean = false;
  mobileNumber: string = '';
  fullName: string = '';
  emailID: string = '';
  mobileDiv: boolean = true;
  otpDiv: boolean = false;
  loading: boolean = false;
  SignUpDiv: boolean = false;
  spinner: boolean = false;
  countdown: Subscription | undefined;
  counter: number = 60;
  tick = 1000;
  timerCountdown: string = '';
  countdownCompleted: boolean = false;
  error_message: string = '';
  error_Message_MobileNo: string = '';
  error_Message_Name: string = '';
  error_Message_Email: string = '';
  mobileHeader: string = 'Welcome to MultiplyRR';
  screenType: string = '';
  emailRegex = /^\S+@\S+\.\S+$/;
  registerToken: any;
  OTPError: string = '';
  isEdit: boolean = true;
  previousRouteName: string = '';
  websiteURL: string = 'https://www.equentis.com/researchandranking/';
  subscribeData: any;
  customer_Id: any;

  constructor(
    private apiService: ApiServiceService,
    private _toastSerivce: ToastrService,
    private route: Router,
    private router: ActivatedRoute,
    private screenTypeService: ScreenTypeService,
    private authService: AuthService,
    private user_service: UserService,
    private onboardingService: OnboardingService,
    private moengageService: MoengageService,
  ) {}

  goToTermsOfService() {
    window.open(`${this.websiteURL+'term-of-service'}`);
  }

  goToPrivacyPolicy() {
    window.open(`${this.websiteURL + 'privacy-policy-and-legal-disclaimer'}`);
  }

  ngOnInit() {
    if (this.screenTypeService.breakpointState !== 'mob') {
      this.route.navigate(['']);
    }
    this.router.paramMap.subscribe((params: any) => {
      let customData = history.state.customData;
      this.subscribeData = customData;
      this.previousRouteName = customData.customData;
      console.log('got prevoius route', customData.customData);
    });
  }

  onTermsChange(data: any) {
    if (data.checked) {
      this.termsCondition = false;
      this.termsData = true;
    } else {
      this.termsCondition = true;
      this.termsData = false;
    }
  }

  goToPreviousPage() {
    // if (this.previousRouteName) {
    //   this.route.navigate([this.previousRouteName]);
    // } else {
    //   this.route.navigate(['']);
    // }
    this.route.navigate(['']);
  }

  onMobileChange() {
    if (this.mobileNumber.length === 10 && !['6', '7', '8', '9'].includes(this.mobileNumber.charAt(0))) {
      this.isMobileSuffix = true;
    } else {
      this.isMobileSuffix = false;
    }
  }

  onNameChange() {
    if (this.fullName) {
      this.isNameSuffix = true;
    } else {
      this.isNameSuffix = false;
    }
  }

  onEmailChange() {
    if (this.emailID && this.emailRegex.test(this.emailID) === true) {
      this.isEmailSuffix = true;
    } else {
      this.isEmailSuffix = false;
    }
  }

  onGetOTP() {
    if (
      this.mobileNumber.length < 10 ||
      /[0-5]/.test(this.mobileNumber.charAt(0))
    ) {
      this.error_Message_MobileNo = 'Please enter valid mobile number';
      this.isMobileSuffix = false;
    } else if (this.termsData == false) {
      this.isMobileSuffix = true;
      this.error_Message_MobileNo = '';
      this.termsCondition = true;
    } else {
      this.isMobileSuffix = true;
      this.termsCondition = false;
      let reqData = {
        mobile_number: this.mobileNumber,
      };
      this.authService.getOTP(reqData).subscribe((res: any) => {
        if (res.success) {
          if (res.data.error) {
            this.SignUpDiv = true;
            this.mobileDiv = false;
            this.otpDiv = false;
          } else {
            this.error_Message_MobileNo = '';
            this.mobileDiv = false;
            this.otpDiv = true;
            this.mobileHeader = 'Enter the OTP';
            this.initCountdownTimer(1);
          }
        }
      });
    }
  }

  resendOTP() {
    let reqData = {
      mobile_number: this.mobileNumber,
    };
    this.authService.getOTP(reqData).subscribe((res: any) => {
      if (res.success) {
        if (res.data.error) {
          this.SignUpDiv = false;
          this.mobileDiv = false;
          this.otpDiv = true;
          this.initCountdownTimer(1);
        } else {
          this.error_Message_MobileNo = '';
          this.mobileDiv = false;
          this.otpDiv = true;
          this.mobileHeader = 'Enter the OTP';
          this.initCountdownTimer(1);
        }
      }
    });
  }

  onSignup() {
    if (this.fullName) {
      this.isNameSuffix = true;
    }
    if (this.emailID) {
      this.isEmailSuffix = true;
    }
    if (
      this.fullName &&
      this.emailID &&
      this.emailRegex.test(this.emailID) === true
    ) {
      this.error_Message_Email = '';
      this.error_Message_Name = '';
      let reqData = {
        mobile_number: this.mobileNumber,
        name: this.fullName,
        email: this.emailID,
      };
      this.authService.signUp(reqData, this.registerToken).subscribe(
        (res: any) => {
          if (res.success && !res.data.error) {
            localStorage.setItem('authToken', res.data.token);
            // this._toastSerivce.success('SignUp successfull');
            // window.location.reload();
            this.loading = false;
            this.isEdit = false;
            this.mobileNumber = this.emailID;
            this.otpDiv = true;
            this.SignUpDiv = false;
            this.mobileDiv = false;
            this.initCountdownTimer(1);
          } else {
            this._toastSerivce.error(res.data.error);
          }
        },
        (err: any) => {
          this._toastSerivce.error('something went wrong!');
        }
      );
    } else {
      if (!this.fullName) {
        this.error_Message_Name = 'Please enter full name';
        this.error_Message_Email = '';
        this.isNameSuffix = false;
      } else if (
        !this.emailID ||
        this.emailRegex.test(this.emailID) === false
      ) {
        this.error_Message_Email = 'Please enter valid email Id';
        this.error_Message_Name = '';
        this.isEmailSuffix = false;
      }
    }
  }

  editMobile() {
    this.mobileDiv = true;
    this.otpDiv = false;
    this.mobileHeader = 'Welcome to MultiplyRR';
  }

  initCountdownTimer(counter: number) {
    if (this.countdown) {
      this.countdown.unsubscribe();
    }

    this.countdownCompleted = false;
    this.counter = counter * 60;

    // Start the timer
    this.countdown = timer(0, this.tick).subscribe(() => {
      --this.counter;

      if (this.counter <= 0) {
        this.countdownCompleted = true;
        this.countdown?.unsubscribe();
      }
    });
  }

  saveDataInRedis(data: any) {
    let payload = {
      id: this.user_service.getHashKey(),
      data: {
        categoryId: data.categoryId,
        subProductId: data.subProductId,
        onboardingId: data.onboardingId,
        productCode: data.productCode,
      }
    }
    this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
      console.log('saved data in redis', res);
      this.route.navigate(['dashboard']);
    });
    
}

subscribeBasket(data: any, index: any, is_subscribed: any, tokenData: any) {
  debugger
  var jsonString = this.apiService.decodeToken(tokenData.split('.')[1]);
  var data_token = JSON.parse(jsonString);
  localStorage.setItem('categoryNameMoe', 'discover');
  if(is_subscribed && !data?.is_getting_renewed) {
    this.apiService.getLatestOnboardingDetails(this.customer_Id, data.sub_product_code).subscribe((res: any) => {
      console.log('got latest onboarding data', res);
      if(res.success) {
        this.user_service.pushHashKey(res.data.onboarding_hash_key); // push updated hash key in localStorage
        this.apiService.getDataFromRedis(this.user_service.getHashKey()).subscribe((res: any) => {
          if(res && res.success) {
            console.log('got dashboard redis',res);
            let reqData = {
              categoryId: res.data.categoryId,
              subProductId: res.data.subProductId,
              onboardingId: res.data.onboardingId,
              productCode: data.sub_product_code
            }
            this.saveDataInRedis(reqData);
          }
         });
      }
      else {
        this._toastSerivce.error('Error while fetching onboarding details');
      }
    });
  }
  // adding this condition to renew basket
  else if(is_subscribed && data?.is_getting_renewed) {
  this.spinner = true;
  let payload = {
    "contact_id": data_token?.contact_id,
    "sub_product_id": data?.sub_product_id
  }
  let authToken = localStorage.getItem('authToken');
  this.onboardingService.renewOnboarding(payload, authToken).subscribe((res: any) => {
    this.spinner = false;
    console.log('lead creation api res..', res);
    if(res && res.success && Object.keys(res.data).length != 0) {
        const parts = res.data.url.split('/');
        const id = parts[parts.length - 1];
        this.user_service.pushHashKey(id);

        // call redis api to save data
        let payload = {
          id: this.user_service.getHashKey(),
          data: {
            categoryId: data.category_id,
            subProductId: data.sub_product_id,
            onboardingId: res.data.onboarding_id,
            productCode: data.sub_product_code,
          }
        }
        this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
          console.log('got redis es..',res);
        })
        //

        this.route.navigate(['pan', id]);
    }
    else {
      this._toastSerivce.error('Unable to fetch onboarding details, please try again!');
    }
  },
  (err) => {
    this.spinner = false;
  }
  );
  //
  }
  //
  else {
  //call create onboarding API
  this.spinner = true;
  //call moengage subscribeNow event
  let eventDetails = {
    'basketName': data?.sub_product_name,
    'categoryName': 'discover',
    'basketId': data?.category_id,
    'minInvestmentAmount': data?.pb_details?.min_investment_amount,
    'returns': data?.pb_details?.cagr || 0,
    'returnsDuration': data?.investment_horizon,
    'basketrisk': data?.pb_details?.risk_level,
    'basketprice': data?.fees_details[0]?.details?.advisory_fees
  }
  this.moengageService.subscribeNowEvent(eventDetails);
  //
  let payload = {
    "lead_id": data_token.lead_id,
    "sub_product_id": data.sub_product_id,
    "country_id": data_token.country_id === null ? 0 : data_token.country_id,
    "state_id": 22,
    "complimentary_period": 0,
    "fees_details": data.fees_details[0],
    "first_year_amount": data.fees_details[0].details.max_yearly_amount,
    "advisory_discount_amount": 0,
    "immediate_advisory_payment_amount": data.fees_details[0].details.advisory_fees,
    "advisory_installment_option": data.advisory_installment_option[0],
    "advisory_number_of_installments": data.advisory_installment_option.length,
    "advisory_is_emandate": false,
    "advisory_emandate_date": moment().format('YYYY-MM-DD'),
    "advisory_emandate_discount": 0,
    "email_contact_detail_id": data_token.email_contact_detail_id,
    "whatsapp_contact_detail_id": data_token.mobile_contact_detail_id,
    "sms_contact_detail_id": data_token.mobile_contact_detail_id
  }
  let authToken = localStorage.getItem('authToken');
  this.onboardingService.createOnboarding(payload, authToken).subscribe((res: any) => {
    this.spinner = false;
    console.log('lead creation api res..', res);
    if(res && res.success && Object.keys(res.data).length != 0) {
        const parts = res.data.url.split('/');
        const id = parts[parts.length - 1];
        this.user_service.pushHashKey(id);

        // call redis api to save data
        let payload = {
          id: this.user_service.getHashKey(),
          data: {
            categoryId: data.category_id,
            subProductId: data.sub_product_id,
            onboardingId: res.data.onboarding_id,
            productCode: data.sub_product_code,
          }
        }
        this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
          console.log('got redis es..',res);
        })
        //

        this.route.navigate(['pan', id]);
    }
    else {
      this._toastSerivce.error('Unable to fetch onboarding details, please try again!');
    }
  },
  (err) => {
    this.spinner = false;
  }
  );
  //
  }
}

  onMobileOtpChanges(otp: string) {
    console.log('On Otp Changes : ', otp);
    if (otp.trim().length == 4) {
      this.loading = true;
      let reqData: any;
      reqData = {
        mobile_number: this.mobileNumber,
        otp: otp,
      };
      if (this.emailID) {
        reqData = {
          email: this.mobileNumber,
          otp: otp,
        };
      }
      this.authService.verifyOTP(reqData).subscribe((res: any) => {
        console.log(res);
        if (res.success && !res.data.error) {
          this.OTPError = '';
          if (res.data.is_registered) {
            localStorage.setItem('authToken', res.data.token);
            this.SignUpDiv = false;
            // window.location.reload();
            let token = localStorage.getItem('authToken') || '';
            let data = null
            if (token) {
              var jsonString = this.apiService.decodeToken(token.split('.')[1]);
              data = JSON.parse(jsonString);
            }
            if (token && data.customer_meta_info.is_registered) {
              this.customer_Id = data.contact_id;
            }
            if(this.subscribeData.subscribeType == 'discover') {
              this.subscribeBasket(this.subscribeData.data, this.subscribeData.index, this.subscribeData.is_subscribed, res.data.token);
            }
            else {
              if (this.previousRouteName) {
                this.route.navigate([this.previousRouteName]);
              } else {
                this.route.navigate(['']);
              }
            }
          } else {
            if (this.emailID) {
              // window.location.reload();
              if (this.previousRouteName) {
                this.route.navigate([this.previousRouteName]);
              } else {
                this.route.navigate(['']);
              }
            } else {
              this.registerToken = res.data.token;
              this.mobileHeader = 'Please register yourself';
              this.SignUpDiv = true;
              this.mobileDiv = false;
              this.otpDiv = false;
            }
          }
        } else if (res.data.error) {
          this.loading = false;
          this.OTPError = res.data.error;
        }
      });
    }
  }

  formatCountDownPipe(): string {
    const minutes: number = Math.floor(this.counter / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(this.counter - minutes * 60)).slice(-2)
    );
  }
}
