import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { PlanSelectionComponent } from '../../shared/dialogs/plan-selection/plan-selection.component';
import { ScreenTypeService } from '../../services/screen-type.service';
import { StepsManagerService } from '../../services/steps-manager.service';
import { ApiServiceService } from '../../services/api-service.service';
import { BasketMoreDetailsComponent } from '../basket-more-details/basket-more-details.component';
import { OnboardingService } from '../../services/onboarding.service';
import { MoengageService } from '../../services/moengage.service';

@Component({
  selector: 'app-risk-profile-result',
  templateUrl: './risk-profile-result.component.html',
  styleUrls: ['./risk-profile-result.component.scss'],
})
export class RiskProfileResultComponent implements OnInit {
  constructor(
    public screenType: ScreenTypeService,
    private user_service: UserService,
    private route: Router,
    private toast_message: ToastrService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RiskProfileResultComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private steps_service: StepsManagerService,
    private apiService: ApiServiceService,
    private onboardingService: OnboardingService,
    private moengageService: MoengageService,
  ) {}
  riskType: string = '';
  suitabilityData: any;
  productSuggestions: any[] = [];
  isProduct_matched: boolean = false;
  highRiskData: boolean = false;
  showTooltip = false;
  showLowRiskTooltip = false;
  showMediumRiskTooltip = false;
  suggestionList: any;
  @Output() reviewClicked = new EventEmitter<void>();

  ngOnInit() {
    this.riskType = this.data.riskTypeData;
    this.suitabilityData = this.data.suitabilityAssessment;
    console.log('this.suitabilityData...',this.suitabilityData);

    this.isProduct_matched = !!this.suitabilityData.selected_product.product_match;
    
    this.productSuggestions = this.suitabilityData.product_suggestion;
    let selectedProduct: any = this.suitabilityData.selected_product;

    const selectedIndex = this.productSuggestions.findIndex
    (item => item.name === selectedProduct.name && item.product_match);

    if (selectedIndex !== -1) {
    this.productSuggestions[selectedIndex].isHighlighted = true;
    const selectedProductItem = this.productSuggestions.splice(selectedIndex, 1)[0];
    this.productSuggestions.unshift(selectedProductItem);
    }
    let eventSubStatus = 'Risk Status Confirmed';
    if(!this.suitabilityData.selected_product.product_match) {
      eventSubStatus = 'Risk Status Mismatched';
    }
    // call moe event
    let eventData = this.onboardingService.getMoeEventDetails();
    let eventMoeData = {
     'onboardingId': eventData?.onboardingId,
     'plan': eventData?.plan,
     'basketPrice': eventData?.basketPrice,
     'basketRisk': eventData?.basketRisk,
     'status': eventData?.status,
     'subStatus': eventSubStatus,
     'basketName': eventData?.basketName,
    }
    this.moengageService.onboardingEvent(eventMoeData);
    //

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  reviewAnswers() {
    this.closeDialog();
    this.onboardingService.setValueReviewAnswer(true);
    this.reviewClicked.emit();
  }

  basketMoreDetails(card: any) {
    console.log('jkdsjkdsjkdsjkdsjkds',card);
    const dialogRef = this.dialog.open(BasketMoreDetailsComponent, {
      height: 'auto',
      disableClose: false,
      autoFocus: false,
      backdropClass: 'backdropBackground',
      width: '100%',
      data: {
      moreDetails: card,
      parentDialogRef: this.dialogRef
      },
    });
  }

  subscribeProduct(product: any, productname: any, isHighlighted: any, code: any) {
    console.log('sjksjksjksjk', product)
  if(isHighlighted) {
   this.dialogRef.close();
  //  this.route.navigate(['bank_account', this.user_service.getHashKey()]);
  this.steps_service.initializeAndNavigate();
  }
  else {
    let moeEventData = this.onboardingService.getMoeEventDetails();
    moeEventData.basketPrice = product?.fees_details[0]?.details.advisory_fees
    moeEventData.basketName = product?.sub_product_name;
    moeEventData.basketRisk = product?.pb_details?.risk_level;
    this.onboardingService.setMoeEventDetails(moeEventData);
    // call moengage subscribeNow event
    let eventDetails = {
      'basketName': product?.sub_product_name,
      'categoryName': 'Risk Profile',
      'basketId': product?.category_id,
      'minInvestmentAmount': product?.pb_details?.min_investment_amount,
      'returns': product?.pb_details?.cagr || 0,
      'returnsDuration': product?.investment_horizon,
      'basketrisk': product?.pb_details?.risk_level,
      'basketprice': product?.fees_details[0]?.details?.advisory_fees
    }
    this.moengageService.subscribeNowEvent(eventDetails);
    //
    for(let data of this.suitabilityData.product_suggestion) {
      if(productname == data.name) {
      if(data.product_risk == this.riskType || this.riskType == 'High') {
       this.openPlanSelectionPopUp(productname,code, product.fees_details);
       // this.dialogRef.close();
       // this.route.navigate(['bank-details', this.user_service.getHashKey()]);
      }
      else if(this.riskType == 'Medium' && data.product_risk == 'High') {
       this.toast_message.error('Risk mismatch, kindly review your answers');
      }
      else {
       this.toast_message.error('Risk mismatch, kindly review your answers');
      }
      }
     }
  }
  }

  addToWatchList(data: any) {
    console.log(data);
    let payload = {
      sub_product_id: data.sub_product_id
    }
    this.apiService.addToWatchList(payload).subscribe((res: any) => {
      console.log(res);
      if(res && res.success == true) {
        this.toast_message.success('added to watchlist successfully!');
      }
      else {
        this.toast_message.error('failed to add to watchlist!');
      }
    })
  }

  openPlanSelectionPopUp(productname: any, code: any, fees_details: any) {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(PlanSelectionComponent, {
      height: '70%',
      disableClose: true,
      autoFocus: false,
      width: '60%',
      data: {
        productName: productname,
        productCode: code,
        fees_details: fees_details
      },
    });
  }

  confirmRiskProfile() {
    this.onboardingService
      .confirmRiskProfile()
      .subscribe();
  }

  onSubmit() {
  if (this.isProduct_matched) {
  this.dialogRef.close();
  console.log('Go to bank details page');
  let id = this.user_service.getHashKey();
  // this.route.navigate(['bank_account', id]);
  this.confirmRiskProfile();
  this.steps_service.initializeAndNavigate();
  } else {
  const errorMessage = 'Product mismatch, please review your answers';
  let suggestionRiskType = this.suggestionList.product_risk;
  console.log(suggestionRiskType);
  console.log(this.riskType);
  
  if (this.suggestionList && (suggestionRiskType == 'Medium' && this.riskType == 'High')) {
      this.toast_message.error(errorMessage);
  } else {
      this.toast_message.error(errorMessage);
  }
  }
  }

  onProductSelection(data: any, index: any) {
    this.productSuggestions.forEach((item, i) => {
      item.isHighlighted = i === index;
  });
  this.suggestionList = data;
  console.log(this.suggestionList);
  }
}
