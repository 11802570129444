import { Component, OnInit, ViewEncapsulation, ViewChild, Inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription, timer } from 'rxjs';
import { ScreenTypeService } from 'src/app/core/services/screen-type.service';
import { ApiServiceService } from 'src/app/core/services/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { PinBoxComponent } from 'src/app/core/shared/elements/pin-box/pin-box.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MoengageService } from 'src/app/core/services/moengage.service';
import { UserService } from 'src/app/core/services/user.service';
import { OnboardingService } from 'src/app/core/services/onboarding.service';
import * as moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  mobileNumberPrevious: any;
  closeLoginRoute: boolean = false;
  spinner: boolean = false;
  constructor(
    private screenTypeService: ScreenTypeService,
    private apiService: ApiServiceService,
    private _toastSerivce: ToastrService,
    private route: Router,
    private authService: AuthService,
    private moengageService: MoengageService,
    private user_service: UserService,
    public dialogRef: MatDialogRef<LoginComponent>,
    private onboardingService: OnboardingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data?.mobileNumber) {
      this.mobileNumber = this.data?.mobileNumber;
    }
    if (this.data?.closeLogin) {
      this.closeLoginRoute = this.data?.closeLogin;
    }
  }
  @ViewChild(PinBoxComponent) appPinBox!: PinBoxComponent;
  isMobileSuffix: boolean = false;
  isNameSuffix: boolean = false;
  isEmailSuffix: boolean = false;
  termsCondition: boolean = false;
  termsData: boolean = false;
  mobileNumber: string = '';
  fullName: string = '';
  emailID: string = '';
  mobileDiv: boolean = true;
  otpDiv: boolean = false;
  loading: boolean = false;
  SignUpDiv: boolean = false;
  countdown: Subscription | undefined;
  counter: number = 60;
  tick = 1000;
  timerCountdown: string = '';
  countdownCompleted: boolean = false;
  error_message: string = '';
  error_Message_MobileNo: string = '';
  error_Message_Name: string = '';
  error_Message_Email: string = '';
  mobileHeader: string = 'Welcome to MultiplyRR';
  screenType: string = '';
  emailRegex = /^\S+@\S+\.\S+$/;
  registerToken: any;
  OTPError: string = '';
  isEdit: boolean = true;
  websiteURL: string = 'https://www.equentis.com/researchandranking/';
  customer_Id: any;

  images = [
    '../../../../../assets/image.svg',
    '../../../../../assets/image_1.svg',
    '../../../../../assets/image_2.svg'
  ];
  selectedIndex = 0;
  selectedImage = this.images[this.selectedIndex];
  intervalId: any;
  customerObject = {};
  mobilenoEvent: any;

  goToTermsOfService(event: Event) {
    event.stopPropagation();
    window.open(`${this.websiteURL + 'term-of-service'}`);
  }

  goToPrivacyPolicy(event: Event) {
    event.stopPropagation();
    window.open(`${this.websiteURL + 'privacy-policy-and-legal-disclaimer'}`);
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  ngOnInit() {
    this.screenType = this.screenTypeService.breakpointState;
    this.startImageRotation();
  }

  startImageRotation() {
    this.intervalId = setInterval(() => {
      this.selectedIndex = (this.selectedIndex + 1) % this.images.length;
      this.selectedImage = this.images[this.selectedIndex];
    }, 3000);
  }

  changeImage(index: number) {
    this.selectedIndex = index;
    this.selectedImage = this.images[index];
    clearInterval(this.intervalId);
    this.startImageRotation();
  }

  onTermsChange(data: any) {
    if (data.checked) {
      this.termsCondition = false;
      this.termsData = true;
    } else {
      this.termsCondition = true;
      this.termsData = false;
    }
  }

  onMobileChange() {
    if (this.mobileNumber.length === 10 && !['6', '7', '8', '9'].includes(this.mobileNumber.charAt(0))) {
      this.isMobileSuffix = true;
    } else {
      this.isMobileSuffix = false;
    }
  }


  onNameChange() {
    if (this.fullName) {
      this.isNameSuffix = true;
      this.error_Message_Name = '';
    } else {
      this.isNameSuffix = false;
    }
  }

  onEmailChange() {
    if (this.emailID && this.emailRegex.test(this.emailID) === true) {
      this.isEmailSuffix = true;
      this.error_Message_Email = '';
    } else {
      this.isEmailSuffix = false;
    }
  }

  onGetOTP() {
    this.OTPError = '';
    this.mobileNumber = this.mobileNumber.toString();
    this.mobilenoEvent = this.mobileNumber;
    if (
      this.mobileNumber.length < 10 ||
      /[0-5]/.test(this.mobileNumber.charAt(0))
    ) {
      this.error_Message_MobileNo = 'Please enter valid mobile number';
      this.isMobileSuffix = false;
    } else if (this.termsData == false) {
      this.isMobileSuffix = true;
      this.error_Message_MobileNo = '';
      this.termsCondition = true;
    } else {
      this.isMobileSuffix = true;
      this.termsCondition = false;
      let reqData = {
        mobile_number: this.mobileNumber,
      };
      this.authService.getOTP(reqData).subscribe((res: any) => {
        if (res.success) {
          if (res.data.error) {
            this.SignUpDiv = true;
            this.mobileDiv = false;
            this.otpDiv = false;
          } else {
            this.mobileNumberPrevious = this.mobileNumber;
            this.error_Message_MobileNo = '';
            this.mobileDiv = false;
            this.otpDiv = true;
            this.mobileHeader = 'Enter the OTP';
            this.initCountdownTimer(1);
          }
        }
      });
    }
  }

  resendOTP() {
    let reqData = {};
    if (this.mobileNumber.includes('@')) {
      reqData = {
        email: this.mobileNumber,
      };
    }
    else {
      reqData = {
        mobile_number: this.mobileNumber,
      };
    }

    this.appPinBox.clearFields();
    this.authService.getOTP(reqData).subscribe((res: any) => {
      if (res.success) {
        if (res.data.error) {
          this.SignUpDiv = false;
          this.mobileDiv = false;
          this.otpDiv = true;
          this.initCountdownTimer(1);
        } else {
          this.error_Message_MobileNo = '';
          this.mobileDiv = false;
          this.otpDiv = true;
          this.mobileHeader = 'Enter the OTP';
          this.initCountdownTimer(1);
        }
      }
    });
  }

  onSignup() {
    if (this.fullName) {
      this.isNameSuffix = true;
    }
    if (this.emailID) {
      this.isEmailSuffix = true;
    }
    if (
      this.fullName &&
      this.emailID &&
      this.emailRegex.test(this.emailID) === true
    ) {
      this.error_Message_Email = '';
      this.error_Message_Name = '';
      let reqData = {
        mobile_number: this.mobileNumberPrevious,
        name: this.fullName,
        email: this.emailID,
      };
      this.authService.signUp(reqData, this.registerToken).subscribe(
        (res: any) => {
          if (res.success && !res.data.error) {
            localStorage.setItem('authToken', res.data.token);
            // this._toastSerivce.success('SignUp successfull');
            // window.location.reload();
            this.loading = false;
            this.isEdit = true;
            this.mobileNumber = this.emailID;
            this.otpDiv = true;
            this.SignUpDiv = false;
            this.mobileDiv = false;
            this.initCountdownTimer(1);
          } else {
            this._toastSerivce.error(res.data.error);
          }
        },
        (err: any) => {
          this._toastSerivce.error('something went wrong!');
        }
      );
    } else {
      if (!this.fullName) {
        this.error_Message_Name = 'Please enter full name';
        this.isNameSuffix = false;
      } else {
        this.error_Message_Name = ''; // Clear the error if fullName is provided
      }

      if (!this.emailID || this.emailRegex.test(this.emailID) === false) {
        this.error_Message_Email = 'Please enter valid email Id';
        this.isEmailSuffix = false;
      } else {
        this.error_Message_Email = ''; // Clear the error if emailID is valid
      }
    }
  }

  closeDialog(): void {
    if (this.closeLoginRoute) {
      this.dialogRef.close();
      this.route.navigate(['']);
    }
    else {
      this.dialogRef.close();
    }
  }

  editMobile() {
    if (this.mobileNumber.includes('.com')) {
      this.SignUpDiv = true;
      this.mobileDiv = false;
      this.otpDiv = false;
      this.mobileHeader = 'Please register yourself';
    } else {
      this.mobileDiv = true;
      this.SignUpDiv = false;
      this.otpDiv = false;
      this.mobileHeader = 'Welcome to MultiplyRR';
    }
  }

  initCountdownTimer(counter: number) {
    if (this.countdown) {
      this.countdown.unsubscribe();
    }

    this.countdownCompleted = false;
    this.counter = counter * 60;

    // Start the timer
    this.countdown = timer(0, this.tick).subscribe(() => {
      --this.counter;

      if (this.counter <= 0) {
        this.countdownCompleted = true;
        this.countdown?.unsubscribe();
      }
    });
  }

  saveDataInRedis(data: any) {
    let payload = {
      id: this.user_service.getHashKey(),
      data: {
        categoryId: data.categoryId,
        subProductId: data.subProductId,
        onboardingId: data.onboardingId,
        productCode: data.productCode,
      }
    }
    this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
      console.log('saved data in redis', res);
      this.route.navigate(['dashboard']);
    });
    
}

subscribeBasket(data: any, index: any, is_subscribed: any, tokenData: any) {
  debugger
  var jsonString = this.apiService.decodeToken(tokenData.split('.')[1]);
  var data_token = JSON.parse(jsonString);
  localStorage.setItem('categoryNameMoe', 'discover');
  if(is_subscribed && !data?.is_getting_renewed) {
    this.apiService.getLatestOnboardingDetails(this.customer_Id, data.sub_product_code).subscribe((res: any) => {
      console.log('got latest onboarding data', res);
      if(res.success) {
        this.user_service.pushHashKey(res.data.onboarding_hash_key); // push updated hash key in localStorage
        this.apiService.getDataFromRedis(this.user_service.getHashKey()).subscribe((res: any) => {
          if(res && res.success) {
            console.log('got dashboard redis',res);
            let reqData = {
              categoryId: res.data.categoryId,
              subProductId: res.data.subProductId,
              onboardingId: res.data.onboardingId,
              productCode: data.sub_product_code
            }
            this.saveDataInRedis(reqData);
          }
         });
      }
      else {
        this._toastSerivce.error('Error while fetching onboarding details');
      }
    });
  }
  // adding this condition to renew basket
  else if(is_subscribed && data?.is_getting_renewed) {
  this.spinner = true;
  let payload = {
    "contact_id": data_token?.contact_id,
    "sub_product_id": data?.sub_product_id
  }
  let authToken = localStorage.getItem('authToken');
  this.onboardingService.renewOnboarding(payload, authToken).subscribe((res: any) => {
    this.spinner = false;
    console.log('lead creation api res..', res);
    if(res && res.success && Object.keys(res.data).length != 0) {
        const parts = res.data.url.split('/');
        const id = parts[parts.length - 1];
        this.user_service.pushHashKey(id);

        // call redis api to save data
        let payload = {
          id: this.user_service.getHashKey(),
          data: {
            categoryId: data.category_id,
            subProductId: data.sub_product_id,
            onboardingId: res.data.onboarding_id,
            productCode: data.sub_product_code,
          }
        }
        this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
          console.log('got redis es..',res);
        })
        //

        this.route.navigate(['pan', id]);
    }
    else {
      this._toastSerivce.error('Unable to fetch onboarding details, please try again!');
    }
  },
  (err) => {
    this.spinner = false;
  }
  );
  //
  }
  //
  else {
  //call create onboarding API
  this.spinner = true;
  //call moengage subscribeNow event
  let eventDetails = {
    'basketName': data?.sub_product_name,
    'categoryName': 'discover',
    'basketId': data?.category_id,
    'minInvestmentAmount': data?.pb_details?.min_investment_amount,
    'returns': data?.pb_details?.cagr || 0,
    'returnsDuration': data?.investment_horizon,
    'basketrisk': data?.pb_details?.risk_level,
    'basketprice': data?.fees_details[0]?.details?.advisory_fees
  }
  this.moengageService.subscribeNowEvent(eventDetails);
  //
  let payload = {
    "lead_id": data_token.lead_id,
    "sub_product_id": data.sub_product_id,
    "country_id": data_token.country_id === null ? 0 : data_token.country_id,
    "state_id": 22,
    "complimentary_period": 0,
    "fees_details": data.fees_details[0],
    "first_year_amount": data.fees_details[0].details.max_yearly_amount,
    "advisory_discount_amount": 0,
    "immediate_advisory_payment_amount": data.fees_details[0].details.advisory_fees,
    "advisory_installment_option": data.advisory_installment_option[0],
    "advisory_number_of_installments": data.advisory_installment_option.length,
    "advisory_is_emandate": false,
    "advisory_emandate_date": moment().format('YYYY-MM-DD'),
    "advisory_emandate_discount": 0,
    "email_contact_detail_id": data_token.email_contact_detail_id,
    "whatsapp_contact_detail_id": data_token.mobile_contact_detail_id,
    "sms_contact_detail_id": data_token.mobile_contact_detail_id
  }
  let authToken = localStorage.getItem('authToken');
  this.onboardingService.createOnboarding(payload, authToken).subscribe((res: any) => {
    this.spinner = false;
    console.log('lead creation api res..', res);
    if(res && res.success && Object.keys(res.data).length != 0) {
        const parts = res.data.url.split('/');
        const id = parts[parts.length - 1];
        this.user_service.pushHashKey(id);

        // call redis api to save data
        let payload = {
          id: this.user_service.getHashKey(),
          data: {
            categoryId: data.category_id,
            subProductId: data.sub_product_id,
            onboardingId: res.data.onboarding_id,
            productCode: data.sub_product_code,
          }
        }
        this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
          console.log('got redis es..',res);
        })
        //

        this.route.navigate(['pan', id]);
    }
    else {
      this._toastSerivce.error('Unable to fetch onboarding details, please try again!');
    }
  },
  (err) => {
    this.spinner = false;
  }
  );
  //
  }
}


  onMobileOtpChanges(otp: string) {
    console.log('On Otp Changes : ', otp);
    if (otp.trim().length == 4) {
      this.loading = true;
      let reqData: any;
      reqData = {
        mobile_number: this.mobileNumber,
        otp: otp,
      };
      if (this.emailID) {
        reqData = {
          email: this.mobileNumber,
          otp: otp,
        };
      }
      this.authService.verifyOTP(reqData).subscribe((res: any) => {
        if (res.success && !res.data.error && res.data.token) {
          this.OTPError = '';
          if (res.data.is_registered) {
            localStorage.setItem('authToken', res.data.token);
            this.SignUpDiv = false;
            //calling meongage event to register
            let token = localStorage.getItem('authToken') || '';
            let data = null
            if (token) {
              var jsonString = this.apiService.decodeToken(token.split('.')[1]);
              data = JSON.parse(jsonString);
            }
            if (token && data.customer_meta_info.is_registered) {
              this.customer_Id = data.contact_id;
            }
            if (!this.emailID) {
              this.customerObject = {
                customer_id: this.customer_Id,
                mobileNumber: this?.mobilenoEvent || data?.mobile_number,
                emailID: this?.emailID || data?.email_address,
                fullName: this?.fullName || data?.full_name
              }
              this.moengageService.createLoginEvent(this.customerObject);
              // eventName = 'MultiplyRR_Login';
            } else {
              this.customerObject = {
                customer_id: this.customer_Id,
                mobileNumber: this?.mobilenoEvent || data?.mobile_number,
                emailID: this?.emailID || data?.email_address,
                fullName: this?.fullName || data?.full_name
              }
              this.moengageService.createLoginEvent(this.customerObject);
              // eventName = 'MultiplyRR_SignUp';
            }
            //
            //calling onboarding from discover page
            if(this.data?.subscribeType == 'discover') {
              this.closeDialog();
              this.subscribeBasket(this.data.data, this.data.index, this.data.is_subscribed, res.data.token);
            }
            else {
              if (this.data?.mobileNumber) {
                this.closeDialog();
                this.route.navigate(['discover']);
              }
              else {
                window.location.reload();
                this.route.navigate(['']);
              }
            }
          } else {
            if (this.emailID) {
              window.location.reload();
              this.route.navigate(['']);
            } else {
              this.registerToken = res.data.token;
              this.mobileHeader = 'Please register yourself';
              this.SignUpDiv = true;
              this.mobileDiv = false;
              this.otpDiv = false;
            }
          }
        } else if (res.data.error) {
          this.loading = false;
          this.OTPError = res.data.error;
        }
      });
    }
  }

  formatCountDownPipe(): string {
    const minutes: number = Math.floor(this.counter / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(this.counter - minutes * 60)).slice(-2)
    );
  }

}
